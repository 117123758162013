import { DialogService } from 'primeng/dynamicdialog';
import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { LoginService } from 'src/app/service/login.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  screenWidth!: number;
  usuario!: string;
  items!: any[];
  itemsResponsivo!: any[];
  deferredPrompt: any;

 

  constructor(
    public globalVarsService: GlobalVarsService,
    public loginService: LoginService
  ) {
    this.getScreenSize(undefined);
  }

  ngOnInit(): void {
    
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event: any) {
    this.screenWidth = window.innerWidth;
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e: any) {
    //console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    // this.showButton = true;
  }

  isInStandaloneMode() {
    let myNavigator: any;
    myNavigator = window.navigator;
    return (window.matchMedia('(display-mode: standalone)').matches) || (myNavigator.standalone) || document.referrer.includes('android-app://');
  }  

  addToHomeScreen() {
    // Show the prompt
    this.deferredPrompt.prompt();
    // this.deferredPrompt.showInstallPromotion();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult: { outcome: string; }) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }

}
