<p-toast></p-toast>
<div style="width: 100%">
    <div class="content">
        <form ngNoForm>
            <h3>Relatórios</h3>            

            <h4>Faça o upload do relatório com as prestações de contas</h4>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="prestacaoContas">Adicionar Prestação de Contas*</label>
                    <p-fileUpload 
                        mode="basic" 
                        chooseLabel="Clique aqui para adicionar (xls, csv ou pdf)" 
                        name="prestacaoContas[]" 
                        [customUpload]="true" 
                        accept="application/pdf"
                        [maxFileSize]="5119998"
                        [auto]="true"
                        (uploadHandler)="onUpload($event, 'uploadedFilePrestacaoContas')"
                        chooseIcon="pi-upload"
                        class="uploadButton">
                    </p-fileUpload>
                    <small *ngIf="uploadedFiles.get('uploadedFilePrestacaoContas') !== null" class="file-uploaded-info">{{ uploadedFiles.get('uploadedFilePrestacaoContas')?.name }}</small>
                </div>
            </div>          

            <div class="form-footer">
                <p-button label="Voltar" styleClass="p-button-success custom-button" routerLink="/home" />

                <p-button label="Gravar" styleClass="p-button-info custom-button" (onClick)="complete()" icon="pi pi-angle-right" iconPos="right" />
            </div>
        </form>
    </div>
</div>