<div style="width: 100%">
    <div class="content">
        <form [formGroup]="formGroup">
            <h3>Cadastro do Condutor</h3>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="nmCondutor">Nome Completo*</label>
                    <input type="text" pInputText formControlName="nmCondutor" class="input" required maxlength="100" />
                    <small
                        *ngIf="(formGroup.get('nmCondutor').invalid && submitted) || (formGroup.get('nmCondutor').dirty && formGroup.get('nmCondutor').invalid)"
                        class="p-error">Nome é Obrigatório.</small>
                </div>
            </div>

            <div class="form-row">
                
                <div class="form-div" style="width: 30%">
                    <label for="nrCpf" >CPF*</label>
                    <p-inputMask mask="999.999.999-99" [unmask]="true" formControlName="nrCpf" (change)="onCpfChange($event)"></p-inputMask>
                    <small *ngIf="(formGroup.get('nrCpf').invalid && submitted) || (formGroup.get('nrCpf').dirty && formGroup.get('nrCpf').invalid)" class="p-error">CPF é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nrDocumIdentid">RG*</label>
                    <input type="text" pInputText formControlName="nrDocumIdentid" class="input" required maxlength="20" />
                    <small *ngIf="(formGroup.get('nrDocumIdentid').invalid && submitted) || (formGroup.get('nrDocumIdentid').dirty && formGroup.get('nrDocumIdentid').invalid)" class="p-error">RG é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nmOrgaoEmIdent">Órgão Expedidor*</label>
                    <input type="text" pInputText formControlName="nmOrgaoEmIdent" class="input" required maxlength="8" (input)="caixaAlta('nmOrgaoEmIdent')"/>
                    <small *ngIf="(formGroup.get('nmOrgaoEmIdent').invalid && submitted) || (formGroup.get('nmOrgaoEmIdent').dirty && formGroup.get('nmOrgaoEmIdent').invalid)" class="p-error">Órgão Expedidor é Obrigatório.</small>
                </div>
                
            </div>

            <div class="form-row">
                
                <div class="form-div" style="width: 22%">
                    <label for="nrCnh" >CNH*</label>
                    <input type="text" pInputText formControlName="nrCnh" class="input" required maxlength="15" (input)="caixaAlta('nrCnh')"/>
                    <small *ngIf="(formGroup.get('nrCnh').invalid && submitted) || (formGroup.get('nrCnh').dirty && formGroup.get('nrCnh').invalid)" class="p-error">CNH é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 22%">
                    <label for="dsCategoriaCnh" >CATEGORIA CNH*</label>
                    <!-- <input type="text" pInputText formControlName="nrCnh" class="input" required maxlength="15" />
                     -->
                     <p-dropdown [options]="categoriasCnh" formControlName="dsCategoriaCnh" placeholder="Selecione" [style]="{'maxWidth':'100%', 'minWidth':'100%'}"></p-dropdown>
                    <small *ngIf="(formGroup.get('dsCategoriaCnh').invalid && submitted) || (formGroup.get('dsCategoriaCnh').dirty && formGroup.get('dsCategoriaCnh').invalid)" class="p-error">Categoria CNH é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 22%">
                    <label for="dsExpedidorCnh">Órgão Expedidor*</label>
                    <input type="text" pInputText formControlName="dsExpedidorCnh" class="input" required maxlength="10" (input)="caixaAlta('dsExpedidorCnh')"/>
                    <small *ngIf="(formGroup.get('dsExpedidorCnh').invalid && submitted) || (formGroup.get('dsExpedidorCnh').dirty && formGroup.get('dsExpedidorCnh').invalid)" class="p-error">Órgão Expedidor é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 22%">
                    <label for="dtVencimentoCnh">Data de Validade*</label>                    
                    <p-calendar dateFormat="dd/mm/yy" formControlName="dtVencimentoCnh" [required]="true" ></p-calendar>
                    <small *ngIf="formGroup.get('dtVencimentoCnh').hasError('required') && submitted" class="p-error">Data de Validade é Obrigatório.</small>
                    <small *ngIf="formGroup.get('dtVencimentoCnh').hasError('cnhDateInvalid')" class="p-error">CNH Vencida.</small>
                </div>
                
            </div>      
            
            <div class="form-row">
                
                <div class="form-div" style="width: 30%">
                    <label for="dtNascimento">Data de Nascimento*</label>                    
                    <p-calendar dateFormat="dd/mm/yy" formControlName="dtNascimento" [required]="true" ></p-calendar>
                    <small *ngIf="(formGroup.get('dtNascimento').invalid && submitted) || (formGroup.get('dtNascimento').dirty && formGroup.get('dtNascimento').invalid)" class="p-error">Data de Nascimento é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="dsEmail">E-mail*</label>
                    <input type="dsEmail" pInputText formControlName="dsEmail" class="input" required maxlength="100" />
                    <small *ngIf="(formGroup.get('dsEmail').invalid && submitted) || (formGroup.get('dsEmail').dirty && formGroup.get('dsEmail').invalid)" class="p-error">E-mail é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nrTelefoneContato">Telefone Contato*</label>
                    <p-inputMask mask="(99)99999-9999" [unmask]="true" formControlName="nrTelefoneContato"></p-inputMask>
                    <small *ngIf="(formGroup.get('nrTelefoneContato').invalid && submitted) || (formGroup.get('nrTelefoneContato').dirty && formGroup.get('nrTelefoneContato').invalid)" class="p-error">Telefone é Obrigatório.</small>
                </div>
                
            </div>                

            <h3>Endereço</h3>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="dsLogradouro">Logradouro*</label>
                    <input type="text" pInputText formControlName="dsLogradouro" class="input" required maxlength="280" />
                    <small *ngIf="(formGroup.get('dsLogradouro').invalid && submitted) || (formGroup.get('dsLogradouro').dirty && formGroup.get('dsLogradouro').invalid)" class="p-error">Logradouro é Obrigatório.</small>
                </div>
            </div>

            <div class="form-row">
                <div class="form-div" style="width: 30%">
                    <label>Bairro*</label>
                    <input type="text" pInputText formControlName="nmBairro" class="input" required maxlength="100" />
                    <small *ngIf="(formGroup.get('nmBairro').invalid && submitted) || (formGroup.get('nmBairro').dirty && formGroup.get('nmBairro').invalid)" class="p-error">Bairro é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nrNroLogradouro">Número*</label>
                    <input type="number" pInputText formControlName="nrNroLogradouro" class="input" required min="0" max="9999999999" maxlength="10" />
                    <small *ngIf="(formGroup.get('nrNroLogradouro').invalid && submitted) || (formGroup.get('nrNroLogradouro').dirty && formGroup.get('nrNroLogradouro').invalid)" class="p-error">Número é Obrigatório.</small>
                </div>                
                <div class="form-div" style="width: 30%;">
                    <label for="nrCep">CEP*</label>
                    <div class="p-inputgroup" >
                        <p-inputMask mask="99999-999" formControlName="nrCep" [unmask]="true"></p-inputMask>
                        <p-button styleClass="p-button-warning cep-button" icon="pi pi-search" (onClick)="buscarEndereco()" ></p-button>
                    </div>
                    <small *ngIf="(formGroup.get('nrCep').invalid && submitted) || (formGroup.get('nrCep').dirty && formGroup.get('nrCep').invalid)" class="p-error">CEP é Obrigatório.</small>
                </div>             
                
            </div>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="dsComplemento">Complemento</label>
                    <input type="text" pInputText formControlName="dsComplemento" class="input" maxlength="50" />
                </div>
            </div>

            <div class="form-row">
                <div class="form-div" style="width: 30%">
                    <label for="nmCidade">Cidade*</label>
                    <input type="text" pInputText formControlName="nmCidade" class="input" required maxlength="50" />
                    <small *ngIf="(formGroup.get('nmCidade').invalid && submitted) || (formGroup.get('nmCidade').dirty && formGroup.get('nmCidade').invalid)" class="p-error">Cidade é Obrigatório.</small>
                </div>
                <div class="form-div" style="width: 30%">
                    <label>Estado*</label>
                    <p-dropdown placeholder="Selecione" autoWidth="false" [style]="{ width: '100%' }"
                        [options]="estadoList" optionLabel="cdEstado" optionValue="cdEstado"
                        formControlName="cdEstado"></p-dropdown>
                        <small *ngIf="(formGroup.get('cdEstado').invalid && submitted) || (formGroup.get('cdEstado').dirty && formGroup.get('cdEstado').invalid)" class="p-error">Estado é Obrigatório.</small>
                </div>
            </div>


            <div class="form-footer">
                <p-button label="Voltar" styleClass="p-button-success custom-button" routerLink="/home" />

                <p-button label="Avançar" styleClass="p-button-info custom-button" (onClick)="nextPage()" icon="pi pi-angle-right" iconPos="right" [disabled]="formGroup.invalid"/>
            </div>
        </form>
    </div>
</div>

<app-endereco-dialog
  [estadoList]="estadoList"
  [displayDialog]="displayDialog"
  (enderecoSelecionado)="selecionarEndereco($event)"
></app-endereco-dialog>