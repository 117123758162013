import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OperadoraDadosComponent } from './operadora-dados/operadora-dados.component';
import { OperadoraDocumentosComponent } from './operadora-documentos/operadora-documentos.component';
import { OperadoraComponent } from './operadora.component';

const routes: Routes = [
  {
    path: '', component: OperadoraComponent,
    children: [
      { path: 'dados', component: OperadoraDadosComponent },
      { path: 'dados/:acao', component: OperadoraDadosComponent },
      { path: 'documentos', component: OperadoraDocumentosComponent },      
      { path: '', redirectTo: 'dados', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperadoraRoutingModule { }
