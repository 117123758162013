import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

// Valida se cnh já está expirada
export function cnhDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
  
      if (!value) {
        return null;
      }

    // Converte a string de data para um objeto Date
    //const partesData = value.split('/');
    const dataInformada: any = value;

    // Obtém a data atual
    const dataAtual: any = new Date();

    // Calcula a diferença em milissegundos entre as duas datas
    const diferencaEmMilissegundos = dataAtual - dataInformada;

    // Calcula o número de dias de diferença
    const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

    // Retorna true se a diferença for maior a 30 dias, caso contrário, retorna false
    return diferencaEmDias > 30 ? {cnhDateInvalid: true} : null;
  }
}