import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog-motivo-reprovacao',
  templateUrl: './dialog-motivo-reprovacao.component.html',
  styleUrls: ['./dialog-motivo-reprovacao.component.scss'],
})
export class DialogMotivoReprovacaoComponent {
  @Input() displayDialog: boolean = false;
  @Input() inputData: any;
  @Output() data: EventEmitter<any> = new EventEmitter<any>();

  dsMotivoReprovacao: string = null;

  onRemove() {
    const data = {
      dsMotivoReprovacao: this.dsMotivoReprovacao,
      oidList: this.inputData.oidList,
    };

    this.data.emit(data);
  }

  clearDialog() {
    this.dsMotivoReprovacao = null;
    this.inputData = null;
    //this.displayDialog = false;
    this.data.emit(null);
  }
}
