import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  // Defina a lista de itens do menu
  menuItems: MenuItem[] =
    localStorage.getItem('idPerfil') == 'PMU'
      ? [
          {
            text: 'Aprovar Operadoras',
            iconClass: 'pi pi-unlock',
            routerLink: '/aprovacoes/operadoras',
          },
          {
            text: 'Aprovar Condutores',
            iconClass: 'pi pi-unlock',
            routerLink: '/aprovacoes/condutores',
          },
          {
            text: 'Aprovar Veículos',
            iconClass: 'pi pi-unlock',
            routerLink: '/aprovacoes/veiculos',
          },
          {
            text: 'Consultar Operadoras',
            iconClass: 'pi pi-search',
            routerLink: '/consulta/operadoras',
          },
          {
            text: 'Consultar Condutores',
            iconClass: 'pi pi-search',
            routerLink: '/consulta/condutores',
          },
          {
            text: 'Consultar Veículos',
            iconClass: 'pi pi-search',
            routerLink: '/consulta/veiculos',
          },
          { text: 'Sair', iconClass: 'pi pi-power-off', routerLink: '/logout' },
        ]
      : [
          {
            text: 'Página Inicial',
            iconClass: 'pi pi-home',
            routerLink: '/home',
          },
          {
            text: 'Operadora',
            iconClass: 'pi pi-building',
            routerLink: '/operadora',
          },
          {
            text: 'Condutor',
            iconClass: 'pi pi-user',
            routerLink: '/condutores',
          },
          { text: 'Veículo', iconClass: 'pi pi-car', routerLink: '/veiculos' },

          {
            text: 'Relatórios',
            iconClass: 'pi pi-file-import',
            routerLink: '/relatorios',
          },
          {
            text: 'Dúvidas Frequentes',
            iconClass: 'pi pi-question',
            routerLink: '/perguntas-frequentes',
          },
          { text: 'Sair', iconClass: 'pi pi-power-off', routerLink: '/logout' },
        ];

  activeMenuItem: string | null = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalVarsService: GlobalVarsService,
    private loginService: LoginService
  ) {}

  ngOnInit(): void {}

  navigateTo(routerLink: string): void {
    if (routerLink !== '/logout') {
      this.activeMenuItem = routerLink;
      this.router.navigate([routerLink]);
    } else {
      this.loginService.logout();
    }
  }

  obtemNomeUsuario(): string {
    return this.globalVarsService.getUserInfo()?.nmUsuario;
  }
}

// Interface para os itens do menu
interface MenuItem {
  text: string;
  iconClass: string;
  routerLink: string;
}
