import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { LoginRequest } from 'src/app/model/login-request-model';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { LoginService } from 'src/app/service/login.service';
import { preencherComZero } from 'src/app/shared/utils/string-utils';
import { environment } from 'src/environments/environment';
import { messageError } from '../../shared/utils/error-utils';

declare var require: any;

@Component({
  selector: 'app-login-eautoriza',
  templateUrl: './login-eautoriza.component.html',
  styleUrls: ['./login-eautoriza.component.scss'],
})
export class LoginEAutorizaComponent implements OnInit {
  show: boolean = false;
  esqueceuSenha: string;
  oidSistemas!: string;

  screenWidth = window.innerWidth;

  loginForm: FormGroup = new FormGroup({
    login: new FormControl('', [Validators.required]),
    senha: new FormControl('', [Validators.required]),
    manterconectado: new FormControl(false),
  });

  constructor(
    private router: Router,
    private messageService: MessageService,
    private loginService: LoginService,
    private spinner: NgxSpinnerService,
    private globalVarsService: GlobalVarsService
  ) {}

  ngOnInit() {}

  changeShow() {
    this.show = !this.show;
  }

  login() {
    const loginRequest: LoginRequest = <LoginRequest>{};
    loginRequest.username = this.loginForm.get('login')?.value;
    loginRequest.password = this.loginForm.get('senha')?.value;
    loginRequest.oidSistemas = JSON.stringify(environment.oidSistema);

    // loginRequest.manterconectado = this.loginForm.get('manterconectado')?.value;

    this.spinner.show();
    this.loginService
      .loginEAutoriza(loginRequest)
      .subscribe({
        next: (result) => {
          if (result.sucesso) {
            let informacoesUsuario = result.informacoesUsuario;

            //seta 1 dia para expirar login
            informacoesUsuario.exp = new Date();
            informacoesUsuario.exp.setDate(
              informacoesUsuario.exp.getDate() + 1
            );
            ////////////////////////////////
            informacoesUsuario.cpf = preencherComZero(informacoesUsuario.cpf);
            //this.globalVarsService.setUsuarioLogado(informacoesUsuario);
            this.globalVarsService.setUserInfo(informacoesUsuario);
            this.globalVarsService.setToken(result.token);
            this.globalVarsService.setPerfil('PMU');
            this.router.navigate(['/home']);
          } else {
            alert(result.mensagem);

            this.messageService.add({
              severity: 'error',
              summary: 'Erro!',
              detail: result.mensagem,
            });
          }
        },
        error: (error) => {
          alert(messageError(error));

          this.messageService.add({
            severity: 'error',
            summary: 'Erro!',
            detail: messageError(error),
          });
        },
      })
      .add(() => this.spinner.hide());
  }

  /* private login(token: string): void {
    this.globalVars.setToken(token);

    history.pushState('login', 'Login', '/login');

    this.spinner.show();

    this.loginWS
      .login(this.env.oidSistema())
      .subscribe({
        next: (result) => {
          if (result.sucesso) {
            this.authService.login(result);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Erro!',
              detail: result.mensagem,
            });
          }
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro!',
            detail: messageError(error),
          });
        },
      })
      .add(() => this.spinner.hide());
  } */
}
