export function isEmpty(str?: string): boolean {
  return str == null || str.trim() == '';
}

export function isNotEmpty(str?: string): boolean {
  return str != null && str.trim() != '';
}

export function allEqual(string: string) {
  for (var i = 1; i < string.length; i++) {
    if (string[i] != string[0]) {
      return false;
    }
  }
  return true;
}

export function removeAcentoToUpperCase(text: string) {
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  text = text.toUpperCase();
  return text;
}

export function removeAcentoCaracteresEspeciais(text: string) {
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  text = text.replace(/[^a-z0-9]/gi, '');
  return text;
}

export function preencherComZero(cpf: string) {
  const tamanhoDesejado = 11;
  const cpfComZero = "0".repeat(tamanhoDesejado - cpf.length) + cpf;
  return cpfComZero;
}
