import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Localizacao } from 'src/app/model/localizacao.model';
import { EnderecoService } from 'src/app/service/endereco.service';
import { removeAcentoToUpperCase } from 'src/app/shared/utils/string-utils';

@Component({
  selector: 'app-endereco-dialog',
  templateUrl: './endereco-dialog.component.html',
  styleUrls: ['./endereco-dialog.component.scss']
})
export class EnderecoDialogComponent {
  @Input() estadoList: any[];
  @Input() displayDialog: boolean;
  @Output() enderecoSelecionado: EventEmitter<any> = new EventEmitter<any>();

  nmLogradouroDialog: string = '';
  codCidDialog: number = null;
  codEstDialog: any;
  cidadeListDialog: any[] = [];
  cidades: any[] = [];
  enderecos: any[] = [];
  localizacoes: Localizacao[];

  constructor(
    private enderecoService: EnderecoService,
    private messageService: MessageService
  ) { }

  selecionarEndereco(endereco: any) {
    this.enderecoSelecionado.emit(endereco); 
  }

  limpaDialog() {
    this.nmLogradouroDialog = '';
    this.localizacoes = [];
  }

  setCidadesDialogByCodEst(event: any, onInit?: boolean) {

    const selectedValue = event.value;

    if (typeof selectedValue === 'string') {
      var codEst = selectedValue;
      this.enderecoService.findCidades(codEst).subscribe((data) => {
        this.cidadeListDialog = data;
  
        if (onInit)
          this.codCidDialog = this.cidadeListDialog.find(
            (el) => el.nmCidade === 'UBERLANDIA'
          )?.codCid;
  
        if (this.cidades?.length <= 0) {
          this.cidades = data;
        }
      });
    } 

  }

  formataTextoNome(event) {
    if (event.target.value) {
      event.target.value = removeAcentoToUpperCase(event.target.value);
    }
  }

  getEnderecoPorNomeCodCid() {
    if (this.nmLogradouroDialog.trim().length > 0 && this.codCidDialog) {
      this.enderecoService
        .findPorLogradouroCidade(this.nmLogradouroDialog, this.codCidDialog)
        .subscribe(
          (data) => {
            this.enderecos = data;
          },
          (error) => {
            this.enderecos = [];
            this.messageService.add({
              severity: 'error',
              summary: 'Erro',
              detail: `${error?.error?.message}`,
            });
          }
        );
    } else if (this.nmLogradouroDialog.trim().length === 0) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Preencha o logradouro para buscar',
      });
    } else if (!this.codCidDialog) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Selecione um estado e cidade para buscar',
      });
    }
  }
}
