<div style="width: 100%">
  <div class="content">
    <form>
      <h3>Documentos do Condutor</h3>
      <h4>Adicione abaixo o(s) documento(s) solicitado(s)*</h4>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="CNH">Adicionar CNH*</label>
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="CNH[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileCNH')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="uploadedFiles.get('uploadedFileCNH') !== null"
            class="file-uploaded-info"
            >{{ uploadedFiles.get("uploadedFileCNH")?.name }}</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="comprovanteEndereco"
            >Adicionar Comprovante de Endereço*</label
          >
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="comprovanteEndereco[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileComprovanteEndereco')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="
              uploadedFiles.get('uploadedFileComprovanteEndereco') !== null
            "
            class="file-uploaded-info"
            >{{
              uploadedFiles.get("uploadedFileComprovanteEndereco")?.name
            }}</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="uploadedFileInscricaoInss">Inscriçao do INSS*</label>
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="uploadedFileInscricaoInss[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileInscricaoInss')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="uploadedFiles.get('uploadedFileInscricaoInss') !== null"
            class="file-uploaded-info"
            >{{ uploadedFiles.get("uploadedFileInscricaoInss")?.name }}</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="uploadedFileTermoCompromisso"
            >Termo de Compromisso*</label
          >
          <p-button
            label="Baixar Termo Compromisso"
            icon="pi pi-download"
            iconPos="left"
            (click)="baixarModeloTermoCompromisso()"
          ></p-button>
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="uploadedFileTermoCompromisso[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileTermoCompromisso')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>

          <small
            *ngIf="uploadedFiles.get('uploadedFileTermoCompromisso') !== null"
            class="file-uploaded-info"
            >{{
              uploadedFiles.get("uploadedFileTermoCompromisso")?.name
            }}</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="uploadedFileCertidaoNegativaCriminal"
            >Certidão Negativa Criminal*</label
          >
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="uploadedFileCertidaoNegativaCriminal[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="
              onUpload($event, 'uploadedFileCertidaoNegativaCriminal')
            "
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="
              uploadedFiles.get('uploadedFileCertidaoNegativaCriminal') !== null
            "
            class="file-uploaded-info"
            >{{
              uploadedFiles.get("uploadedFileCertidaoNegativaCriminal")?.name
            }}</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="uploadedFileAtestadoAntecedentesCriminais"
            >Atestar de Antecedentes Criminais*</label
          >
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="uploadedFileAtestadoAntecedentesCriminais[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="
              onUpload($event, 'uploadedFileAtestadoAntecedentesCriminais')
            "
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="
              uploadedFiles.get('uploadedFileAtestadoAntecedentesCriminais') !==
              null
            "
            class="file-uploaded-info"
            >{{
              uploadedFiles.get("uploadedFileAtestadoAntecedentesCriminais")
                ?.name
            }}</small
          >
        </div>
      </div>

      <div class="form-footer">
        <p-button
          label="Voltar"
          styleClass="p-button-success custom-button"
          (onClick)="prevPage()"
        />

        <p-button
          label="Concluir"
          styleClass="p-button-info custom-button"
          (onClick)="complete()"
          icon="pi pi-angle-right"
          iconPos="right"
        />
      </div>
    </form>
  </div>
</div>
