import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OperadoraComponent } from './operadora.component';
import { OperadoraDadosComponent } from './operadora-dados/operadora-dados.component';
import { OperadoraDocumentosComponent } from './operadora-documentos/operadora-documentos.component';
import { OperadoraRoutingModule } from './operadora-routing.module';
import { ComponentModule } from 'src/app/component/component.module';
import { OperadoraService } from './operadora.service';

import { DropdownModule } from 'primeng/dropdown';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    OperadoraComponent,
    OperadoraDadosComponent,
    OperadoraDocumentosComponent
  ],
  imports: [
    CommonModule,
    OperadoraRoutingModule,
    ComponentModule,
    DropdownModule,
    StepsModule,
    ToastModule,
    ButtonModule,
    FileUploadModule,
    InputMaskModule,
    ReactiveFormsModule,
    TableModule,
    FormsModule,
    CheckboxModule
  ],
  bootstrap: [OperadoraComponent],
  providers: [ OperadoraService ]
})
export class OperadoraModule { }
