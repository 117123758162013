<div class="city-background" id="home">
    <div class="overlay"></div>
    <div class="center-2">
      <div class="menu">        
      </div>
    </div>
    <div class="center">
      <div class="container">
        <div style="display: inline-flex;">
          <div class="h1" style="width: 55%;">
            <h1>
              Cadastro <br />
              de Operadora
            </h1>
          </div>
          
          <div class="button" style="margin-top: auto; margin-left: 10px; padding-left: 50px;  cursor: pointer; z-index: 99;">
            <button (click)="acessoPessoaJuridica()">Acessar Pessoa Jurídica</button>
          </div>
        </div>
        <div class="span">
          <span>
            Olá! Caso deseje cadastrar sua operadora, clique no botão acima para efetuar o login. 
          </span>
        </div>     
      
    </div>
  </div>