import { Component, OnInit } from '@angular/core';
import { OperadoraService } from '../operadora.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { CadastroOperadora } from 'src/app/model/cadastro-operadora.model';
import { FormGroup } from '@angular/forms';
import { FileUploadEvent } from 'primeng/fileupload';

@Component({
  selector: 'app-operadora-documentos',
  templateUrl: './operadora-documentos.component.html',
  styleUrls: ['./operadora-documentos.component.scss']
})
export class OperadoraDocumentosComponent implements OnInit {
  public formGroup: FormGroup | undefined;
  cadastroOperadora: CadastroOperadora;

  check: boolean = false;

  uploadedFiles: Map<string, File | null> = new Map<string, File | null>();

  constructor(
    public operadoraService: OperadoraService,
    private router: Router,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.cadastroOperadora = this.operadoraService.getFormularioCadastro().cadastroOperadora;
    this.uploadedFiles.set('uploadedFileContratoSocial', this.operadoraService.getFormularioCadastro().uploadedFileContratoSocial);
    this.uploadedFiles.set('uploadedFileCertidaoNegativa', this.operadoraService.getFormularioCadastro().uploadedFileCertidaoNegativa);
    this.uploadedFiles.set('uploadedFileCartaoCnpj', this.operadoraService.getFormularioCadastro().uploadedFileCartaoCnpj);
    this.uploadedFiles.set('uploadedFileComprovanteRegularidadeInss', this.operadoraService.getFormularioCadastro().uploadedFileComprovanteRegularidadeInss);
    this.uploadedFiles.set('uploadedFileComprovanteRegularidadeFgts', this.operadoraService.getFormularioCadastro().uploadedFileComprovanteRegularidadeFgts);
  }

  onUpload(event: any, fieldName: string) {
    if (event.files.length > 0) {
      this.uploadedFiles.set(fieldName, event.files[0]);
      this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: event.files[0].name });
    } else {
      this.uploadedFiles.set(fieldName, null);
    }
  }

  complete() {
    if(this.validaFormularioCadastro()){
      this.processarCadastroOperadora();
    }
  }  

  private processarCadastroOperadora() {
    this.spinner.show();
    const formData: FormData = this.getFormData();
    console.log(this.cadastroOperadora?.oidCadastroOperadoras);
    const operation = this.cadastroOperadora?.oidCadastroOperadoras ? this.operadoraService.update(formData) : this.operadoraService.create(formData);

    operation.subscribe(
      (result) => this.handleCadastroResult(result),
      (error) => this.handleError(error),
      () => this.spinner.hide()
    );
  }

  private handleCadastroResult(result: any) {
    if (result != null && result.oidCadastroOperadoras != null) {
      this.cadastroOperadora.oidCadastroOperadoras = result.oidCadastroOperadoras;
      this.operadoraService.complete();
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar/atualizar Operadora!' });
    }
  }

  private handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar/atualizar Operadora!' });
  }

  private getFormData(): FormData {
    const formData = new FormData();
    formData.append('oidCadastroOperadoras', this.cadastroOperadora?.oidCadastroOperadoras?.toString() || '');
    formData.append('nmFantasia', this.cadastroOperadora?.nmFantasia || '');
    formData.append('nmRazaoSocial', this.cadastroOperadora?.nmRazaoSocial || '');
    formData.append('nrCnpj', this.cadastroOperadora?.nrCnpj || '');
    formData.append('dsEmail', this.cadastroOperadora?.dsEmail || '');
    formData.append('nrTelefoneContato', this.cadastroOperadora?.nrTelefoneContato || '');
    formData.append('nmContato', this.cadastroOperadora?.nmContato || '');
    formData.append('dsLogradouro', this.cadastroOperadora?.dsLogradouro || '');
    formData.append('nmBairro', this.cadastroOperadora?.nmBairro || '');
    formData.append('nrNroLogradouro', this.cadastroOperadora?.nrNroLogradouro?.toString() || '');
    formData.append('nrCep', this.cadastroOperadora?.nrCep || '');
    formData.append('dsComplemento', this.cadastroOperadora?.dsComplemento || '');
    formData.append('nmCidade', this.cadastroOperadora?.nmCidade || '');
    formData.append('cdEstado', this.cadastroOperadora?.cdEstado || '');
    formData.append('situacaoCadastro', this.cadastroOperadora?.situacaoCadastro || 'P');
    formData.append('dsUsuAlter', this.cadastroOperadora?.nrCnpj || '');

    this.uploadedFiles.forEach((file, fieldName) => {
      if (file) {
        formData.append(fieldName, file, file.name);
      }
    });

    return formData;
  }

  prevPage() {
    this.router.navigate(['operadora/dados', 'voltar']);
  }

  private validaFormularioCadastro(): boolean {
    if(this.cadastroOperadora?.oidCadastroOperadoras){
      return true;
    }else{
      const requiredFields = [
        'uploadedFileContratoSocial',
        'uploadedFileCertidaoNegativa'
      ];
  
      for (const fieldName of requiredFields) {
        if (!this.uploadedFiles.get(fieldName)) {
          this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Todos documentos são obrigatórios!' });
          return false;
        }
      }
  
      return true;
    }

  }
}