import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { FileUploadEvent } from 'primeng/fileupload';
import { CadastroCondutores } from 'src/app/model/cadastro-condutor.model';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { downloadFile } from 'src/app/shared/utils/file-utils';
import { CondutoresService } from '../condutores.service';

@Component({
  selector: 'app-condutores-documentos',
  templateUrl: './condutores-documentos.component.html',
  styleUrls: ['./condutores-documentos.component.scss'],
})
export class CondutoresDocumentosComponent implements OnInit {
  public formGroup: FormGroup | undefined;
  cadastroCondutor: CadastroCondutores;

  uploadedFiles: Map<string, File | null> = new Map<string, File | null>();

  constructor(
    public condutorService: CondutoresService,
    private router: Router,
    private globalVarsService: GlobalVarsService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.cadastroCondutor =
      this.condutorService.getFormularioCadastro().cadastroCondutor;
    this.uploadedFiles.set(
      'uploadedFileCNH',
      this.condutorService.getFormularioCadastro().uploadedFileCNH
    );
    this.uploadedFiles.set(
      'uploadedFileComprovanteEndereco',
      this.condutorService.getFormularioCadastro()
        .uploadedFileComprovanteEndereco
    );
    this.uploadedFiles.set(
      'uploadedFileInscricaoInss',
      this.condutorService.getFormularioCadastro().uploadedFileInscricaoInss
    );
    this.uploadedFiles.set(
      'uploadedFileTermoCompromisso',
      this.condutorService.getFormularioCadastro().uploadedFileTermoCompromisso
    );
    this.uploadedFiles.set(
      'uploadedFileCertidaoNegativaCriminal',
      this.condutorService.getFormularioCadastro()
        .uploadedFileCertidaoNegativaCriminal
    );
    this.uploadedFiles.set(
      'uploadedFileAtestadoAntecedentesCriminais',
      this.condutorService.getFormularioCadastro()
        .uploadedFileAtestadoAntecedentesCriminais
    );
  }

  onUpload(event: any, fieldName: string) {
    if (event.files.length > 0) {
      this.uploadedFiles.set(fieldName, event.files[0]);
      this.messageService.add({
        severity: 'info',
        summary: 'File Uploaded',
        detail: event.files[0].name,
      });
    } else {
      this.uploadedFiles.set(fieldName, null);
    }
  }

  complete() {
    if (this.validaFormularioCadastro) {
      this.processarCadastroCondutor();
    }
  }

  private processarCadastroCondutor() {
    this.spinner.show();
    const formData: FormData = this.getFormData();
    console.log(this.cadastroCondutor?.oidCadastroCondutor);
    const operation = this.cadastroCondutor?.oidCadastroCondutor
      ? this.condutorService.update(formData)
      : this.condutorService.create(formData);

    operation.subscribe(
      (result) => this.handleCadastroResult(result),
      (error) => this.handleError(error),
      () => this.spinner.hide()
    );
  }

  private handleCadastroResult(result: any) {
    if (result != null && result.oidCadastroCondutor != null) {
      this.cadastroCondutor.oidCadastroCondutor = result.oidCadastroCondutor;
      this.condutorService.complete();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro!',
        detail: 'Erro ao cadastrar/atualizar Condutor!',
      });
    }
  }

  private handleError(error: any) {
    this.messageService.add({
      severity: 'error',
      summary: 'Erro!',
      detail: 'Erro ao cadastrar/atualizar Condutor!',
    });
    this.spinner.hide();
  }

  private getFormData(): FormData {
    const formData = new FormData();
    const cadastroCondutor = this.cadastroCondutor || {}; // Evita erros se this.cadastroCondutor for nulo

    formData.append(
      'oidCadastroCondutor',
      cadastroCondutor.oidCadastroCondutor?.toString() || ''
    );
    formData.append('nmCondutor', cadastroCondutor.nmCondutor || '');
    formData.append('nrCpf', cadastroCondutor.nrCpf?.toString() || '');
    formData.append('nrDocumIdentid', cadastroCondutor.nrDocumIdentid || '');
    formData.append('nmOrgaoEmIdent', cadastroCondutor.nmOrgaoEmIdent || '');
    formData.append('nrCnh', cadastroCondutor.nrCnh || '');
    formData.append('dsExpedidorCnh', cadastroCondutor.dsExpedidorCnh || '');
    formData.append('dsCategoriaCnh', cadastroCondutor.dsCategoriaCnh || '');

    if (cadastroCondutor.dtVencimentoCnh) {
      formData.append(
        'dtVencimentoCnh',
        cadastroCondutor.dtVencimentoCnh.toISOString()
      );
    }

    formData.append('dsEmail', cadastroCondutor.dsEmail || '');
    formData.append(
      'nrTelefoneContato',
      cadastroCondutor.nrTelefoneContato?.toString() || ''
    );

    if (cadastroCondutor.dtNascimento) {
      formData.append(
        'dtNascimento',
        cadastroCondutor.dtNascimento.toISOString()
      );
    }

    formData.append('dsLogradouro', cadastroCondutor.dsLogradouro || '');
    formData.append('nmBairro', cadastroCondutor.nmBairro || '');
    formData.append(
      'nrNroLogradouro',
      cadastroCondutor.nrNroLogradouro?.toString() || ''
    );
    formData.append('nrCep', cadastroCondutor.nrCep || '');
    formData.append('dsComplemento', cadastroCondutor.dsComplemento || '');
    formData.append('nmCidade', cadastroCondutor.nmCidade || '');
    formData.append('cdEstado', cadastroCondutor.cdEstado || '');
    formData.append(
      'situacaoCadastro',
      cadastroCondutor.situacaoCadastro || 'P'
    );
    formData.append('dsUsuAlter', this.getUserCNPJ() || '');

    this.uploadedFiles.forEach((file, fieldName) => {
      if (file) {
        formData.append(fieldName, file, file.name);
      }
    });

    return formData;
  }

  prevPage() {
    this.router.navigate(['condutor/dados']);
  }

  getUserCNPJ(): string {
    return this.globalVarsService.getUserInfo()?.cnpj;
  }

  private validaFormularioCadastro(): boolean {
    if (this.cadastroCondutor?.oidCadastroCondutor) {
      return true;
    } else {
      const requiredFields = [
        'uploadedFileCNH',
        'uploadedFileComprovanteEndereco',
      ];

      for (const fieldName of requiredFields) {
        if (!this.uploadedFiles.get(fieldName)) {
          this.messageService.add({
            severity: 'error',
            summary: 'Erro!',
            detail: 'Todos documentos são obrigatórios!',
          });
          return false;
        }
      }

      return true;
    }
  }

  baixarModeloTermoCompromisso() {
    this.condutorService.findModeloTermoCompromissos().subscribe((response) => {
      downloadFile(response.arqBase64Bytes, response.nomeArquivo);
    });
  }

  
}
