import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SplitterModule } from 'primeng/splitter';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentModule } from './component/component.module';
import { AprovacoesModule } from './page/aprovacoes/aprovacoes.module';
import { CondutoresModule } from './page/condutores/condutores.module';
import { HomeComponent } from './page/home/home.component';
import { LoginEAutorizaComponent } from './page/login-eautoriza/login-eautoriza.component';
import { LoginComponent } from './page/login/login.component';
import { OperadoraModule } from './page/operadora/operadora.module';
import { PerguntasFrequentesComponent } from './page/perguntas-frequentes/perguntas-frequentes.component';
import { RelatoriosComponent } from './page/relatorios/relatorios.component';
import { VeiculosModule } from './page/veiculos/veiculos.module';
import { AuthService } from './service/auth.service';
import { HttpInterceptorService } from './service/http-interceptor.service';
import { LoginWS } from './service/ws/login.ws';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { TooltipModule } from 'primeng/tooltip';
import { JwtInterceptorService } from './service/jwt-interceptor.service';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PerguntasFrequentesComponent,
    LoginComponent,
    RelatoriosComponent,
    LoginEAutorizaComponent,
  ],
  imports: [
    BrowserModule,
    SplitterModule,
    MenubarModule,
    MenuModule,
    AppRoutingModule,
    PanelModule,
    ComponentModule,
    OperadoraModule,
    CondutoresModule,
    AprovacoesModule,
    VeiculosModule,
    DynamicDialogModule,
    SlideMenuModule,
    HttpClientModule,
    NgxSpinnerModule,
    StepsModule,
    ToastModule,
    ButtonModule,
    FormsModule,
    CardModule,
    InputMaskModule,
    DropdownModule,
    FileUploadModule,
    ReactiveFormsModule,
    DialogModule,
    TableModule,
    BrowserAnimationsModule,
    RouterModule,
    CheckboxModule,
    PasswordModule,
    TooltipModule,
  ],
  providers: [
    HttpClientModule,
    DialogService,
    MessageService,
    AuthService,
    LoginWS,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
