import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './page/home/home.component';
import { authGuard, AuthGuard, notLoggedGuard } from './auth.guard';
import { PerguntasFrequentesComponent } from './page/perguntas-frequentes/perguntas-frequentes.component';
import { LoginComponent } from './page/login/login.component';
import { RelatoriosComponent } from './page/relatorios/relatorios.component';
import { LoginEAutorizaComponent } from './page/login-eautoriza/login-eautoriza.component';
import { adminGuard } from './admin.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [notLoggedGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [authGuard],
  },
  {
    path: 'operadora',
    loadChildren: () =>
      import('./page/operadora/operadora.module').then(
        (m) => m.OperadoraModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'condutores',
    loadChildren: () =>
      import('./page/condutores/condutores.module').then(
        (m) => m.CondutoresModule
      ),
    canActivate: [authGuard],
  },
  {
    path: 'veiculos',
    loadChildren: () =>
      import('./page/veiculos/veiculos.module').then((m) => m.VeiculosModule),
    canActivate: [authGuard],
  },
  {
    path: 'aprovacoes',
    loadChildren: () =>
      import('./page/aprovacoes/aprovacoes.module').then(
        (m) => m.AprovacoesModule
      ),
    canActivate: [adminGuard],
  },
  {
    path: 'consulta',
    loadChildren: () =>
      import('./page/consulta/consulta.module').then((m) => m.ConsultaModule),
    canActivate: [adminGuard],
  },
  {
    path: 'relatorios',
    component: RelatoriosComponent,
    canActivate: [authGuard],
  },
  {
    path: 'perguntas-frequentes',
    component: PerguntasFrequentesComponent,
    canActivate: [authGuard],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login-eautoriza',
    component: LoginEAutorizaComponent,
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
