import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CondutoresDadosComponent } from './condutores-dados/condutores-dados.component';
import { CondutoresDocumentosComponent } from './condutores-documentos/condutores-documentos.component';
import { CondutoresComponent } from './condutores.component';

const routes: Routes = [
  {
    path: '', component: CondutoresComponent,
    children: [
      { path: 'dados', component: CondutoresDadosComponent },
      { path: 'documentos', component: CondutoresDocumentosComponent },
      { path: '', redirectTo: 'dados', pathMatch: 'full' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CondutoresRoutingModule { }
