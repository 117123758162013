import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AprovacoesOperadoraComponent } from './aprovacoes-operadora/aprovacoes-operadora.component';
import { AprovacoesCondutorComponent } from './aprovacoes-condutor/aprovacoes-condutor.component';
import { AprovacoesVeiculoComponent } from './aprovacoes-veiculo/aprovacoes-veiculo.component';
import { AprovacoesService } from './aprovacoes.service';
import { AprovacoesRoutingModule } from './aprovacoes-routing.module';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { ComponentModule } from 'src/app/component/component.module';
import { DialogMotivoReprovacaoComponent } from './dialog-motivo-reprovacao/dialog-motivo-reprovacao.component';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  declarations: [
    AprovacoesOperadoraComponent,
    AprovacoesCondutorComponent,
    AprovacoesVeiculoComponent,
    DialogMotivoReprovacaoComponent,
  ],
  imports: [
    CommonModule,
    AprovacoesRoutingModule,
    CheckboxModule,
    FormsModule,
    TableModule,
    ButtonModule,
    ToastModule,
    ComponentModule,
    DialogModule,
    InputTextModule
  ],
  providers: [AprovacoesService],
})
export class AprovacoesModule {}
