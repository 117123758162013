import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AprovacoesOperadoraComponent } from './aprovacoes-operadora/aprovacoes-operadora.component';
import { AprovacoesCondutorComponent } from './aprovacoes-condutor/aprovacoes-condutor.component';
import { AprovacoesVeiculoComponent } from './aprovacoes-veiculo/aprovacoes-veiculo.component';

const routes: Routes = [

  { path: 'operadoras', component: AprovacoesOperadoraComponent },
  { path: 'condutores', component: AprovacoesCondutorComponent },
  { path: 'veiculos', component: AprovacoesVeiculoComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AprovacoesRoutingModule { }