import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription, catchError, finalize } from 'rxjs';
import { CondutoresService } from './condutores.service';
import { Router } from '@angular/router';
import { OperadoraService } from '../operadora/operadora.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalVarsService } from 'src/app/service/global-vars.service';

@Component({
    selector: 'app-condutores',
    templateUrl: './condutores.component.html',
    styleUrls: ['./condutores.component.scss'],
    providers: [MessageService]
})
export class CondutoresComponent implements OnInit {
    items: MenuItem[];

    subscription: Subscription;

    isOperadoraAprovada: boolean = true;

    constructor(public messageService: MessageService,
        public condutoresService: CondutoresService,
        private router: Router,
        private globalVarsService: GlobalVarsService,
        private operadoraService: OperadoraService,
        private spinner: NgxSpinnerService) { }

    ngOnInit() {
        this.items = [
            {
                label: 'Condutor(a)',
                routerLink: 'dados'
            },
            {
                label: 'Documentos',
                routerLink: 'documentos'
            }
        ];

        this.subscription = this.condutoresService.cadastroFinalizado$.subscribe((condutores) => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Condutor cadastrado com sucesso!' });
            this.router.navigate(['condutores/dados']);
        });

        this.findSituacaoCadastroOperadoraByCnpj();
    }

    private findSituacaoCadastroOperadoraByCnpj() {
        this.spinner.show();

        const cnpj = this.getUserCNPJ();

        this.operadoraService.findSituacaoCadastroOperadoraByCnpj(cnpj).pipe(
            catchError((error) => {
                console.error('Erro ao buscar situação da operadora:', error);
                this.messageService.add({ severity: 'Error', summary: 'Erro ao recuperar situação da operadora', detail: error });
                throw error;
            }),
            finalize(() => {
                this.spinner.hide();
            })
        ).subscribe((data) => {
            if (data) {
                this.isOperadoraAprovada = 'A' === data;
                console.log('Operação de busca concluída com sucesso.');
            } else {
                this.isOperadoraAprovada = false;
                console.info('Nenhuma operadora foi encontrada');
            }
        });
    }

    getUserCNPJ(): string {
        return this.globalVarsService.getUserInfo()?.cnpj;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
