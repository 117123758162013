
export function messageError(error): string {
  console.log(error);
  if (error == null) {
    return 'Erro de servidor, tente mais tarde.';
  } else if (error != null && error.status === 401) {
    return 'Acesso Negado! Clique em Sair e Entre novamente no sistema. Caso continue entre em contato com a SEPLAN.';
  } else if (error != null && error.status === 0) {
    return 'O sistema está temporariamente fora do ar, tente mais tarde.';
  } else {
    if (error != null && error.error != null && error.error.cause != null && error.error.cause.cause != null && error.error.cause.cause.message != null && error.error.cause.cause.message.trim() !== '') {
      return error.error.cause.cause.message;
    } else if (error != null && error.error != null && error.error.message != null && error.error.message.trim() !== '') {
      return error.error.message;
    } else {
      return 'Houve um erro, tente mais tarde, caso prossiga procure a SEPLAN.';
    }
  }
}
