<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<app-header
  *ngIf="this.router.url !== '/login' && this.router.url !== '/login-eautoriza'"
></app-header>
<app-navigator
  *ngIf="this.router.url !== '/login' && this.router.url !== '/login-eautoriza'"
></app-navigator>
<app-footer
  *ngIf="this.router.url !== '/login' && this.router.url !== '/login-eautoriza'"
></app-footer>
<app-btn-topo
  *ngIf="this.router.url !== '/login' && this.router.url !== '/login-eautoriza'"
></app-btn-topo>
<app-login *ngIf="this.router.url == '/login'"></app-login>
<app-login-eautoriza *ngIf="this.router.url == '/login-eautoriza'"></app-login-eautoriza>
