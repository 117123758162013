export function dataURItoBlobTypeZip(dataURI: string) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/zip' });
  return blob;
}

export function dataURItoBlobType(dataURI: string) {
  const byteString = window.atob(dataURI);
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const int8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([int8Array], { type: 'application/msword' });
  return blob;
}

export function downloadFile(base64String: string, fileName: string) {
  // Decode da string base64
  const decodedData = atob(base64String);

  const link = document.createElement('a');
  link.href =
    `data:'application/octet-stream'
      ;base64,` + decodedData;

  // Agora, você pode usar a variável 'decodedData' onde for necessário

  link.download = fileName;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
