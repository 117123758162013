import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AprovacaoCondutor } from 'src/app/model/aprovacao-condutor.model';
import { AprovacaoOperadora } from 'src/app/model/aprovacao-operadora.model';
import { EnvironmentService } from 'src/app/service/environment.service';

@Injectable({
  providedIn: 'root',
})
export class AprovacoesService {
  constructor(
    private httpClient: HttpClient,
    private env: EnvironmentService
  ) {}

  public findOperadorasParaAprovacao(): Observable<AprovacaoOperadora[]> {
    const retorno = this.httpClient.get<AprovacaoOperadora[]>(
      `${this.env.getHostURL()}/operadora/pendente-aprovacao`
    );
    console.log(retorno);
    return retorno;
  }

  public aprovarOperadora(
    oidCadastroOperadorasList: number[]
  ): Observable<any> {
    const object = {
      oidList: oidCadastroOperadorasList,
      tpSituacao: 'A',
    };

    return this.httpClient.put<any>(
      `${this.env.getHostURL()}/operadora/alterar-situacao`,
      object
    );
  }

  public reprovarOperadora(
    oidCadastroOperadorasList: number[],
    dsMotivoReprovacao: string
  ): Observable<any> {
    const object = {
      oidList: oidCadastroOperadorasList,
      tpSituacao: 'R',
      dsMotivoReprovacao: dsMotivoReprovacao,
    };

    return this.httpClient.put<any>(
      `${this.env.getHostURL()}/operadora/alterar-situacao`,
      object
    );
  }

  public findCondutoresParaAprovacao(): Observable<AprovacaoCondutor[]> {
    const retorno = this.httpClient.get<AprovacaoCondutor[]>(
      `${this.env.getHostURL()}/condutor/pendente-aprovacao`
    );
    console.log(retorno);
    return retorno;
  }

  public aprovarCondutor(
    oidCadastroCondutoresList: number[]
  ): Observable<number> {
    const object = {
      oidList: oidCadastroCondutoresList,
      tpSituacao: 'A',
    };

    return this.httpClient.put<number>(
      `${this.env.getHostURL()}/condutor/alterar-situacao`,
      object
    );
  }

  public reprovarCondutor(
    oidCadastroCondutoresList: number[],
    dsMotivoReprovacao: string
  ): Observable<any> {
    const object = {
      oidList: oidCadastroCondutoresList,
      tpSituacao: 'R',
      dsMotivoReprovacao: dsMotivoReprovacao,
    };

    return this.httpClient.put<any>(
      `${this.env.getHostURL()}/condutor/alterar-situacao`,
      object
    );
  }

  public findVeiculosParaAprovacao(): Observable<AprovacaoCondutor[]> {
    const retorno = this.httpClient.get<AprovacaoCondutor[]>(
      `${this.env.getHostURL()}/veiculo/pendente-aprovacao`
    );
    console.log(retorno);
    return retorno;
  }

  public aprovarVeiculo(oidCadastroVeiculosList: number[]): Observable<number> {
    const object = {
      oidList: oidCadastroVeiculosList,
      tpSituacao: 'A',
    };

    return this.httpClient.put<number>(
      `${this.env.getHostURL()}/veiculo/alterar-situacao`,
      object
    );
  }

  public reprovarVeiculo(
    oidCadastroVeiculosList: number[],
    dsMotivoReprovacao: string
  ): Observable<number> {
    const object = {
      oidList: oidCadastroVeiculosList,
      tpSituacao: 'R',
      dsMotivoReprovacao: dsMotivoReprovacao,
    };

    return this.httpClient.put<number>(
      `${this.env.getHostURL()}/veiculo/alterar-situacao`,
      object
    );
  }

  public baixarAnexosOperadoraPorId(oidOperadora: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.getHostURL()}/operadora/buscar-anexos-por-id/${oidOperadora}`
    );
  }

  public baixarAnexosCondutorPorId(oidCondutor: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.getHostURL()}/condutor/buscar-anexos-por-id/${oidCondutor}`
    );
  }

  public baixarAnexosVeiculoPorId(oidVeiculo: number): Observable<any> {
    return this.httpClient.get(
      `${this.env.getHostURL()}/veiculo/buscar-anexos-por-id/${oidVeiculo}`
    );
  }
}
