import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BtnTopoComponent } from './btn-topo/btn-topo.component';
import { NavigatorComponent } from './navigator/navigator.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { EnderecoDialogComponent } from './endereco-dialog/endereco-dialog.component';

import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogOperadoraComponent } from './dialog-operadora/dialog-operadora.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogCondutorComponent } from './dialog-condutor/dialog-condutor.component';
import { CalendarModule } from 'primeng/calendar';
import { DialogVeiculoComponent } from './dialog-veiculo/dialog-veiculo.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DialogObservacaoComponent } from './dialog-observacao/dialog-observacao.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,    
    BtnTopoComponent,
    NavigatorComponent,
    SideMenuComponent,
    EnderecoDialogComponent,
    DialogOperadoraComponent,
    DialogCondutorComponent,
    DialogVeiculoComponent,
    DialogObservacaoComponent,
  ],
  imports: [    
    FormsModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    RouterModule,
    ButtonModule,
    TableModule,
    ReactiveFormsModule,
    InputMaskModule,
    DropdownModule,
    ToastModule,
    ButtonModule,
    FileUploadModule,
    TableModule,
    CalendarModule,
    RadioButtonModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,    
    BtnTopoComponent,
    NavigatorComponent,
    SideMenuComponent,
    EnderecoDialogComponent,
    DialogOperadoraComponent,
    DialogCondutorComponent,
    DialogVeiculoComponent,
    DialogObservacaoComponent
  ],
})
export class ComponentModule {}
