import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ParentService {

  constructor(private http: HttpClient) { }

  getExternalUrl(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }): Observable<any> {
    return new Observable((x) => {
      let req = new XMLHttpRequest();
      req.open('get', requestUrl, true);
      req.send();
      req.onload = function () {
        let data = JSON.parse(req.response);
        x.next(data);
      }
    });
  }

  get(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }, responseType?: any): Observable<any> {
    return this.http.get<any>(requestUrl, { params: params, responseType: responseType });
  }

  getWithCredentials(requestUrl: string, params?: HttpParams | { [param: string]: string | string[]; }, responseType?: any): Observable<any> {
    return this.http.get<any>(requestUrl, { params: params, responseType: responseType, withCredentials: true});
  }

  post(requestUrl: string, data: any): Observable<any> {
    return this.http.post<any>(requestUrl, data);
  }

  postWithAc(requestUrl: string, data: any, param: string): Observable<any> {
    const params = new HttpParams().set("__actg", param);
    return this.http.post<any>(requestUrl, data, {params});
  }

  delete(requestUrl: string): Observable<any> {
    return this.http.delete<any>(requestUrl);
  }

  put(requestUrl: string, data: any): Observable<any> {
    return this.http.put<any>(requestUrl, data);
  }
}
