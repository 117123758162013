<header class="header">
  <div class="header-left">
      <img src="../../../assets/app_logo.png" alt="logo">
      <span class="system-name">CREDENCIAMENTO DE OPERADORA</span>
  </div>
  <div class="header-center"></div>
  <div class="header-right">
      <img src="../../../assets/Retângulo 190.png" alt="logo_prefeitura">
  </div>
</header>
