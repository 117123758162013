import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { ParentService } from './parent.service';
import { GlobalVarsService } from 'src/app/service/global-vars.service';

@Injectable({
  providedIn: 'root',
})
export class EnderecoService extends ParentService {

  constructor(http: HttpClient, private env: EnvironmentService) {
    super(http);
  }

  findEstados(): Observable<any[]> {
    return super.get(`${this.env.getHostURL()}/enderecos/estados`);
  }

  findCidades(codEst: string): Observable<any> {
    return super.get(
      `${this.env.getHostURL()}/enderecos/cidades/${codEst}`
    );
  }

  findPorCep(cep: string): Observable<any> {    
  
    return super.get(
      `${this.env.getHostURL()}/enderecos/cep/${cep}`      
    );
  }

  findPorLogradouroCidade(
    nmLogradouro: string,
    cdCidade: number
  ): Observable<any> {
    let params: any = {
      nmLogradouro: nmLogradouro
    };

    return super.get(
      `${this.env.getHostURL()}/enderecos/cidade/${cdCidade}`,
      params
    );
  }
 
}
