import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from '../environment.service';

@Injectable()
export class LoginWS {
  constructor(
    private httpClient: HttpClient,
    private env: EnvironmentService
  ) {}

  public login(oidSistemas: any): Observable<any> {
    return this.httpClient.post(
      this.env.getHostURLEAutoriza() + '/atualizar-token',
      oidSistemas
    );
  }
}
