import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  public getHostURL(): string {
    return environment.hostURL;
  }

  public getHostURLEAutoriza(): string {
    return environment.hostUrlEAutoriza;
  }

  public getHostURLEAutorizaWeb(): string {
    return environment.hostUrlEAutorizaWeb;
  }

  public oidSistema(): number {
    return environment.oidSistema;
  }

}
