import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroVeiculos } from 'src/app/model/cadastro-veiculo.model';
import { VeiculosService } from '../veiculos/veiculos.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs';
import { CadastroCondutores } from 'src/app/model/cadastro-condutor.model';
import { CondutoresService } from '../condutores/condutores.service';
import { FileUploadEvent } from 'primeng/fileupload';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-veiculos',
  templateUrl: './veiculos.component.html',
  styleUrls: ['./veiculos.component.scss']
})
export class VeiculosComponent implements OnInit {
  public formGroup: FormGroup;
  condutoresList: CadastroCondutores[] = [];
  submitted: boolean = false;
  cadastroVeiculos: CadastroVeiculos;
  displayDialog: boolean = false;
  uploadedFiles: Map<string, File | null> = new Map<string, File | null>();
  oldPlaca: string = '';

  options: any[] = [
    { name: 'Sim', key: 'S' },
    { name: 'Não', key: 'N' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private veiculosService: VeiculosService,
    private condutoresService: CondutoresService,
    private messageService: MessageService,
    private router: Router,
    private globalVarsService: GlobalVarsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.initializeFormGroup();
    this.initializeData();
  } 

  private initializeData(){
    const condutorSelecionado = this.formGroup?.value?.condutor;
    const placaPreenchida = this.formGroup?.value?.nrPlaca;
    
    if(condutorSelecionado == undefined || condutorSelecionado == null || condutorSelecionado == ''){
      this.recuperaCondutores();
    }
    
    this.cadastroVeiculos = {
      oidCadastroVeiculos: undefined,
      tpVeiculos: '',
      nrPlaca: placaPreenchida ? placaPreenchida : '',
      dsChassi: '',
      dsModelo: '',
      nrAno: undefined,
      dsCor: '',
      dsRenavam: '',
      nmProprietario: '',
      dtLaudoVistoria: undefined,
      oidCadastroCondutor: condutorSelecionado ? condutorSelecionado : undefined,
      situacaoCadastro: '',
      proprietarioCondutor: 'S',
      dsUsuAlter: '',
      vsVersao: undefined,
    };

    this.uploadedFiles.set('uploadedFileCRLV', null);
    this.uploadedFiles.set('uploadedFileLaudoVistoria', null);
    this.uploadedFiles.set('uploadedFileApoliceSeguro', null);
    this.uploadedFiles.set('uploadedFileDPVAT', null);

  }

  onUpload(event: any, fieldName: string) {
    if (event.files.length > 0) {
      this.uploadedFiles.set(fieldName, event.files[0]);
      this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: event.files[0].name });
    } else {
      this.uploadedFiles.set(fieldName, null);
    }
  }

  private getFormData(): FormData {
    const formData = new FormData();
    const cadastroVeiculos = this.cadastroVeiculos || {}; 
    console.log(cadastroVeiculos);
    formData.append('oidCadastroVeiculos', cadastroVeiculos.oidCadastroVeiculos?.toString() || '');
    formData.append('tpVeiculos', cadastroVeiculos.tpVeiculos?.toString() || '');
    formData.append('nrPlaca', cadastroVeiculos.nrPlaca?.toString() || '');
    formData.append('dsChassi', cadastroVeiculos.dsChassi?.toString() || '');
    formData.append('dsModelo', cadastroVeiculos.dsModelo?.toString() || '');
    formData.append('nrAno', cadastroVeiculos.nrAno?.toString() || '');
    formData.append('dsCor', cadastroVeiculos.dsCor?.toString() || '');
    formData.append('dsRenavam', cadastroVeiculos.dsRenavam?.toString() || '');
    formData.append('nmProprietario', cadastroVeiculos.nmProprietario?.toString() || '');

    if (cadastroVeiculos.dtLaudoVistoria) {
      formData.append('dtLaudoVistoria', cadastroVeiculos.dtLaudoVistoria.toISOString());
    } 

    formData.append('oidCadastroCondutor', cadastroVeiculos.oidCadastroCondutor?.toString() || '');
    formData.append('proprietarioCondutor', cadastroVeiculos.situacaoCadastro || 'S');
    formData.append('situacaoCadastro', cadastroVeiculos.situacaoCadastro || 'P');
    formData.append('dsUsuAlter', this.getUserCNPJ() || '');
    
    this.uploadedFiles.forEach((file, fieldName) => {
      if (file) {
        formData.append(fieldName, file, file.name);
      }
    });
  
    return formData;
  }  

  private validaFormularioCadastro(): boolean {
    if(this.cadastroVeiculos?.oidCadastroVeiculos){
      return true;
    }else{
      const requiredFields = [
        'uploadedFileCRLV',
        'uploadedFileLaudoVistoria',
        'uploadedFileApoliceSeguro',
        'uploadedFileDPVAT'
      ];
  
      for (const fieldName of requiredFields) {
        if (!this.uploadedFiles.get(fieldName)) {
          this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Todos documentos são obrigatórios!' });
          return false;
        }
      }
  
      return true;
    }

  }

  private recuperaCondutores() {
    this.spinner.show(); 
    const cnpj = this.getUserCNPJ();

    this.condutoresService.findCondutoresDaOperadora(cnpj).pipe(
      catchError((error) => {
        console.error('Erro ao buscar condutores:', error);
        this.messageService.add({ severity: 'Error', summary: 'Erro ao recuperar condutores', detail: error });
        throw error;
      }),
      finalize(() => {                
        this.spinner.hide(); 
      })
    ).subscribe((data) => {
      if (data) {
        this.condutoresList = data; 
        console.log('Busca de condutores concluída com sucesso.');
      }else{

      }
    });
  }

  private findVeiculoDoCondutorPelaPlaca(placa: string) {
    this.spinner.show(); 
    
    this.veiculosService.findVeiculoPelaPlaca(placa).pipe(
      catchError((error) => {
        console.error('Erro ao buscar veículo:', error);
        this.messageService.add({ severity: 'Error', summary: 'Erro ao recuperar veículo', detail: error });
        throw error;
      }),
      finalize(() => {    
        this.spinner.hide(); 
      })
    ).subscribe((data) => {
      if (data && data.oidCadastroVeiculos && data.oidCadastroCondutor) {
        
        if(this.formGroup.value.condutor === data.oidCadastroCondutor) {
          data.dtLaudoVistoria = this.convertDataFormat(data.dtLaudoVistoria); 

          this.cadastroVeiculos = data;
          this.oldPlaca =  this.cadastroVeiculos.nrPlaca;
          this.patchFormValues();  
          this.messageService.add({ severity: 'success', summary: 'Veículo', detail: 'Cadastro do veículo recuperado com sucesso!' });
          console.log('Cadastro do veículo recuperado com sucesso.');
        }else{
          this.messageService.add({ severity: 'Error', summary: 'Veículo já cadastrado', detail: 'Veiculo está cadastrado para outro condutor, favor verificar os dados' })          
          console.log('Cadastro do veículo recuperado com sucesso. Veiculo está cadastrado para outro condutor.');
          this.initializeData();
          this.patchFormValues();  
        }
        
      }else{
        this.initializeData();
        this.patchFormValues();  
      }
    });
  }

  convertDataFormat(data: Date): Date {
    const datePipe = new DatePipe('en-US');
    const dataFormatada = data;
        const dataParaExibir = datePipe.transform(dataFormatada, 'yyyy-MM-dd');
        return new Date(dataParaExibir);
  }

  onPlacaChange(newValue: any) {
  
    const newPlaca = this.formGroup.get('nrPlaca').value;
  
    this.formGroup.get('nrPlaca').markAsTouched();
  
    if (newPlaca !== undefined && newPlaca.length == 7 && newPlaca !== this.oldPlaca) {
      this.findVeiculoDoCondutorPelaPlaca(newPlaca);
    }
  }

  private patchFormValues() {
    const selectedOption = this.options.find(item => item.key === this.cadastroVeiculos.proprietarioCondutor);

    this.formGroup.patchValue({
      tpVeiculos: this.cadastroVeiculos.tpVeiculos,
      nrPlaca: this.cadastroVeiculos.nrPlaca,
      dsChassi: this.cadastroVeiculos.dsChassi,
      dsModelo: this.cadastroVeiculos.dsModelo,
      nrAno: this.cadastroVeiculos.nrAno,
      dsCor: this.cadastroVeiculos.dsCor,
      dsRenavam: this.cadastroVeiculos.dsRenavam,
      nmProprietario: this.cadastroVeiculos.nmProprietario,
      dtLaudoVistoria: this.cadastroVeiculos.dtLaudoVistoria,
      condutor: this.cadastroVeiculos.oidCadastroCondutor,
      selectedOption: selectedOption
    });
  }

  private initializeFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      tpVeiculos: ['', [Validators.required, Validators.maxLength(20)]],
      nrPlaca: ['', [Validators.required, Validators.maxLength(7)]],
      dsChassi: ['', [Validators.required, Validators.maxLength(17)]],
      dsModelo: ['', [Validators.required, Validators.maxLength(15)]],
      nrAno: [null, [Validators.required]],
      dsCor: ['', [Validators.required, Validators.maxLength(20)]],
      dsRenavam: ['', [Validators.required, Validators.maxLength(9)]],
      nmProprietario: ['', [Validators.required, Validators.maxLength(50)]],
      dtLaudoVistoria: [null, [Validators.required]],
      condutor: ['', [Validators.required]], 
      selectedOption: [this.options[0], [Validators.required]],
      idLicenciadoUdi: [null, [Validators.required]],
      idCategoriaVeiculo: [null, [Validators.required]],
      nrApolice: [null, [Validators.required]],
      dtVigenciaSeguroInicio: [null, [Validators.required]],
      dtVigenciaSeguroFim: [null, [Validators.required]],
      dtVigenciaDpvatInicio: [null, [Validators.required]],
      dtVigenciaDpvatFim: [null, [Validators.required]],
    });
  }

  getUserCNPJ(): string {
    return this.globalVarsService.getUserInfo()?.cnpj;
  }

  copiarDadosParaCadastroVeiculos() {
    const formValue = this.formGroup.value;
    console.log(formValue);
    this.cadastroVeiculos.tpVeiculos = formValue.tpVeiculos;
    this.cadastroVeiculos.nrPlaca = formValue.nrPlaca;
    this.cadastroVeiculos.dsChassi = formValue.dsChassi;
    this.cadastroVeiculos.dsModelo = formValue.dsModelo;
    this.cadastroVeiculos.nrAno = formValue.nrAno;
    this.cadastroVeiculos.dsCor = formValue.dsCor;
    this.cadastroVeiculos.dsRenavam = formValue.dsRenavam;
    this.cadastroVeiculos.oidCadastroCondutor = formValue.condutor;
    this.cadastroVeiculos.proprietarioCondutor = formValue.selectedOption.key;
    this.cadastroVeiculos.dtLaudoVistoria = formValue.dtLaudoVistoria;
    if(formValue.selectedOption.key == 'S'){
      
      const condutorSelecionado = this.condutoresList.find(item => item.oidCadastroCondutor === formValue.condutor);

      if (condutorSelecionado) {
        this.cadastroVeiculos.nmProprietario = condutorSelecionado.nmCondutor;
      }

    }else{
      this.cadastroVeiculos.nmProprietario = formValue.nmProprietario;
    }
    console.log('copiarDadosParaCadastroVeiculos');
    console.log(this.cadastroVeiculos);
  }

  configuraSituacaoCadastro(): void {
    if (this.cadastroVeiculos.oidCadastroVeiculos == null) {
      this.cadastroVeiculos.situacaoCadastro = 'P';
    }
  }

  complete() {
    if(this.validaFormularioCadastro){
      this.copiarDadosParaCadastroVeiculos();
      this.processarCadastroVeiculo();
    }
  }  

  private processarCadastroVeiculo() {
    this.spinner.show();
    
    const formData: FormData = this.getFormData();
    console.log(this.cadastroVeiculos?.oidCadastroVeiculos);
    const operation = this.cadastroVeiculos?.oidCadastroVeiculos ? this.veiculosService.update(formData) : this.veiculosService.create(formData);

    operation.pipe(
      catchError((error) => {
        this.handleError(error);
        throw error;
      }),
      finalize(() => {                
        this.spinner.hide(); 
      })
    ).subscribe((data) => {
      if (data) {
        this.handleCadastroResult(data);
      }
    });
  }

  private handleCadastroResult(result: any) {
    if (result != null && result.oidCadastroVeiculos != null) {
      this.cadastroVeiculos.oidCadastroVeiculos = result.oidCadastroVeiculos;
      this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Veículo cadastrado com sucesso!' });
            this.router.navigate(['veiculos']);
    } else {
      this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar/atualizar Veículo!' });
    }
  }

  private handleError(error: any) {
    this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Erro ao cadastrar/atualizar Veículo!' });
  }

  formatarPlaca(fieldName: string) {
    const campo = this.formGroup.get(fieldName);

    if (campo && campo.value) {
      let valorAtual = campo.value.substring(0, 3).replace(/[^a-zA-Z]/g, '').toUpperCase();
      valorAtual += campo.value.substring(3, 7).toUpperCase();
      valorAtual = valorAtual.replace(/[^a-zA-Z0-9]/g, '');

      campo.setValue(valorAtual);
    }
  }

  caixaAlta(fieldName: string) {
    const campo = this.formGroup.get(fieldName);

    if (campo && campo.value) {
      campo.setValue(campo.value.toUpperCase());
    }
  }
}