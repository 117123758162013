import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs';
import { CadastroOperadora } from 'src/app/model/cadastro-operadora.model';
import { OperadoraService } from 'src/app/page/operadora/operadora.service';

@Component({
  selector: 'app-dialog-operadora',
  templateUrl: './dialog-operadora.component.html',
  styleUrls: ['./dialog-operadora.component.scss']
})
export class DialogOperadoraComponent implements OnInit{
  
  @Input() displayDialog: boolean;
  @Input() oidCadastroOperadoras: number;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  cadastroOperadora: CadastroOperadora = {
    oidCadastroOperadoras: null,
    nmFantasia: '',
    nmRazaoSocial: '',
    nrCnpj: '',
    dsEmail: '',
    nrTelefoneContato: '',
    nmContato: '',
    dsLogradouro: '',
    nmBairro: '',
    nrNroLogradouro: null,
    nrCep: '',
    dsComplemento: '',
    nmCidade: '',
    cdEstado: '',
    situacaoCadastro: '',
    dsUsuAlter: '',
    vsVersao: null,
    uploadedFileContratoSocial: '',
    uploadedFileComprovanteEndereco: '',
    uploadedFileInscricaoEstadual: '',
    uploadedFileProcuracao: '',
    uploadedFileCertidaoNegativa: '',
  };

  constructor(
    private operadoraService: OperadoraService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.initializeData();
  }

  private initializeData() {
      this.findOperadoraById();  
  }

  private findOperadoraById() {
    if(this.oidCadastroOperadoras){
      this.spinner.show(); 
  
      this.operadoraService.findOperadoraById(this.oidCadastroOperadoras).pipe(
        catchError((error) => {
          console.error('Erro ao buscar operadora:', error);       
          throw error;
        }),
        finalize(() => {                
          this.spinner.hide(); 
        })
      ).subscribe((data) => {
        if (data && data.oidCadastroOperadoras) {
          console.log(data);
          this.cadastroOperadora = data;
          console.log('Operação de busca concluída com sucesso.');
        }
      });
    }
    }

    limpaDialog() {
      this.closeDialog.emit(); 
    }
}
