<p-toast></p-toast>
<div style="width: 100%">
  <div class="content">
    <form [formGroup]="formGroup">
      <h3>Cadastro de Veículos</h3>

      <div class="form-row">
        <div
          class="form-div"
          style="width: 100%"
          *ngIf="condutoresList && condutoresList.length > 0"
        >
          <label>Condutor*</label>
          <p-dropdown
            placeholder="Selecione"
            autoWidth="false"
            [style]="{ width: '100%' }"
            [options]="condutoresList"
            optionLabel="nmCondutor"
            optionValue="oidCadastroCondutor"
            formControlName="condutor"
          ></p-dropdown>
          <small
            *ngIf="
              formGroup.get('condutor').dirty &&
              formGroup.get('condutor').invalid
            "
            class="p-error"
            >Condutor é Obrigatório.</small
          >
        </div>
        <div
          class="form-div"
          style="width: 100%"
          *ngIf="!condutoresList || condutoresList.length === 0"
        >
          <p class="p-error">
            Não existem condutores cadastrados ativos para esta operadora
          </p>
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 30%">
          <label for="nrPlaca">Placa do Veículo*</label>
          <input
            type="text"
            pInputText
            formControlName="nrPlaca"
            class="input"
            required
            maxlength="7"
            (change)="onPlacaChange($event)"
            (input)="formatarPlaca('nrPlaca')"
          />
          <small
            *ngIf="
              formGroup.get('nrPlaca').dirty && formGroup.get('nrPlaca').invalid
            "
            class="p-error"
            >Placa do Veículo é Obrigatório.</small
          >
        </div>

        <div class="form-div" style="width: 30%">
          <label for="tpVeiculos">Tipo do Veículo*</label>
          <input
            type="text"
            pInputText
            formControlName="tpVeiculos"
            class="input"
            required
            maxlength="20"
            (input)="caixaAlta('tpVeiculos')"
          />
          <small
            *ngIf="
              formGroup.get('tpVeiculos').dirty &&
              formGroup.get('tpVeiculos').invalid
            "
            class="p-error"
            >Tipo do Veículo é Obrigatório.</small
          >
        </div>

        <div class="form-div" style="width: 30%">
          <label for="dsModelo">Modelo do Veículo*</label>
          <input
            type="text"
            pInputText
            formControlName="dsModelo"
            class="input"
            required
            maxlength="15"
            (input)="caixaAlta('dsModelo')"
          />
          <small
            *ngIf="
              formGroup.get('dsModelo').dirty &&
              formGroup.get('dsModelo').invalid
            "
            class="p-error"
            >Modelo do Veículo é Obrigatório.</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 30%">
          <label for="dsCor">Cor do Veículo*</label>
          <input
            type="text"
            pInputText
            formControlName="dsCor"
            class="input"
            required
            maxlength="20"
            (input)="caixaAlta('dsCor')"
          />
          <small
            *ngIf="
              formGroup.get('dsCor').dirty && formGroup.get('dsCor').invalid
            "
            class="p-error"
            >Cor do Veículo é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label for="nrAno">Ano de Fabricação*</label>
          <input
            type="number"
            pInputText
            formControlName="nrAno"
            class="input"
            required
            maxlength="4" restrict-input="^[0-9-]*$" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
          <small
            *ngIf="
              formGroup.get('nrAno').dirty && formGroup.get('nrAno').invalid
            "
            class="p-error"
            >Ano de Fabricação é Obrigatório.</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 30%">
          <label for="dsChassi">Chassi*</label>
          <input
            type="text"
            pInputText
            formControlName="dsChassi"
            class="input"
            required
            maxlength="17"
            (input)="caixaAlta('dsChassi')"
          />
          <small
            *ngIf="
              formGroup.get('dsChassi').dirty &&
              formGroup.get('dsChassi').invalid
            "
            class="p-error"
            >Chassi é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label for="dsRenavam">Renavam*</label>
          <input
            type="number"
            pInputText
            formControlName="dsRenavam"
            class="input"
            required
            maxlength="9" restrict-input="^[0-9-]*$" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          />
          <small
            *ngIf="
              formGroup.get('dsRenavam').dirty &&
              formGroup.get('dsRenavam').invalid
            "
            class="p-error"
            >Renavam é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label for="dtLaudoVistoria">Data Laudo de Inspeção*</label>
          <p-calendar
            dateFormat="dd/mm/yy"
            formControlName="dtLaudoVistoria"
            [required]="true"
          ></p-calendar>
          <small
            *ngIf="
              (formGroup.get('dtLaudoVistoria').invalid && submitted) ||
              (formGroup.get('dtLaudoVistoria').dirty &&
                formGroup.get('dtLaudoVistoria').invalid)
            "
            class="p-error"
            >Data de Validade é Obrigatório.</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 30%">
          <label for="idLicenciadoUdi">Licenciado em Uberlândia?*</label>
          <p-dropdown
            [options]="[
              { label: 'Sim', value: 'S' },
              { label: 'Não', value: 'N' }
            ]"
            optionLabel="label"
            optionValue="value"
            formControlName="idLicenciadoUdi"
            placeholder="Selecione"
            [style]="{ maxWidth: '100%', minWidth: '100%' }"
          ></p-dropdown>
          <small
            *ngIf="
              (formGroup.get('idLicenciadoUdi').invalid && submitted) ||
              (formGroup.get('idLicenciadoUdi').dirty &&
                formGroup.get('idLicenciadoUdi').invalid)
            "
            class="p-error"
            >Campo Licenciado em Uberlândia é Obrigatório.</small
          >
        </div>

        <div class="form-div" style="width: 30%">
          <label for="idCategoriaVeiculo">Categoria Veículo*</label>
          <p-dropdown
            [options]="[
              { label: 'Aluguel', value: 'A' },
              { label: 'Particular', value: 'P' }
            ]"
            optionLabel="label"
            optionValue="value"
            formControlName="idCategoriaVeiculo"
            placeholder="Selecione"
            [style]="{ maxWidth: '100%', minWidth: '100%' }"
          ></p-dropdown>
          <small
            *ngIf="
              (formGroup.get('idCategoriaVeiculo').invalid && submitted) ||
              (formGroup.get('idCategoriaVeiculo').dirty &&
                formGroup.get('idCategoriaVeiculo').invalid)
            "
            class="p-error"
            >Categoria Veículo é Obrigatório.</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 30%">
          <label for="dtVigenciaSeguroInicio"
            >Data Início Vigência Apólice Seguro*</label
          >
          <p-calendar
            formControlName="dtVigenciaSeguroInicio"
            [required]="true"
            [style]="{ width: '100%' }"
            dateFormat="dd/mm/yy"
          ></p-calendar>
          <small
            *ngIf="
              (formGroup.get('dtVigenciaSeguroInicio').invalid &&
                submitted) ||
              (formGroup.get('dtVigenciaSeguroInicio').dirty &&
                formGroup.get('dtVigenciaSeguroInicio').invalid)
            "
            class="p-error"
            >Data de Vigência é Obrigatório.</small
          >
        </div>

        <div class="form-div" style="width: 30%">
          <label for="dtVigenciaSeguroFim"
            >Data Fim Vigência Apólice Seguro*</label
          >
          <p-calendar
            formControlName="dtVigenciaSeguroFim"
            [required]="true"
            [style]="{ width: '100%' }"
            dateFormat="dd/mm/yy"
          ></p-calendar>
          <small
            *ngIf="
              (formGroup.get('dtVigenciaSeguroFim').invalid &&
                submitted) ||
              (formGroup.get('dtVigenciaSeguroFim').dirty &&
                formGroup.get('dtVigenciaSeguroFim').invalid)
            "
            class="p-error"
            >Data de Vigência é Obrigatório.</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 30%">
          <label for="dtVigenciaDpvatInicio">Data Início Vigência DPVAT*</label>
          <p-calendar
            formControlName="dtVigenciaDpvatInicio"
            [required]="true"
            [style]="{ width: '100%' }"
            dateFormat="dd/mm/yy"
          ></p-calendar>
          <small
            *ngIf="
              (formGroup.get('dtVigenciaDpvatInicio').invalid && submitted) ||
              (formGroup.get('dtVigenciaDpvatInicio').dirty &&
                formGroup.get('dtVigenciaDpvatInicio').invalid)
            "
            class="p-error"
            >Data de Vigência é Obrigatório.</small
          >
        </div>

        <div class="form-div" style="width: 30%">
          <label for="dtVigenciaDpvatFim">Data Final Vigência DPVAT*</label>
          <p-calendar
            formControlName="dtVigenciaDpvatFim"
            [required]="true"
            [style]="{ width: '100%' }"
            dateFormat="dd/mm/yy"
          ></p-calendar>
          <small
            *ngIf="
              (formGroup.get('dtVigenciaDpvatFim').invalid && submitted) ||
              (formGroup.get('dtVigenciaDpvatFim').dirty &&
                formGroup.get('dtVigenciaDpvatFim').invalid)
            "
            class="p-error"
            >Data de Vigência é Obrigatório.</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <label for="proprietarioCondutor"
          >O proprietário é condutor do veículo?</label
        >
        <div *ngFor="let option of options" class="field-checkbox">
          <p-radioButton
            [inputId]="option.key"
            [value]="option"
            formControlName="selectedOption"
          ></p-radioButton>
          <label [for]="option.key" class="ml-2">{{ option.name }}</label>
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid &&
          formGroup.get('selectedOption')?.value.key == 'N'
        "
      >
        <div class="form-div" style="width: 100%">
          <label for="nomefantasia">Proprietário do Veículo*</label>
          <input
            type="text"
            pInputText
            formControlName="nmProprietario"
            class="input"
            [attr.required]="formGroup.get('selectedOption')?.value.key == 'N'"
            maxlength="50"
          />
          <small
            *ngIf="
              formGroup.get('nmProprietario').dirty &&
              formGroup.get('nmProprietario').invalid
            "
            class="p-error"
            >Proprietário do Veículo é Obrigatório.</small
          >
        </div>
      </div>

      <h4
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid &&
          formGroup.get('selectedOption')?.value.key == 'N'
        "
      >
        Adicione abaixo o(s) documento(s) solicitado(s)*
      </h4>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 100%">
          <label for="CRLV">Adicionar CRLV*</label>
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="CRLV[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileCRLV')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="uploadedFiles.get('uploadedFileCRLV') !== null"
            class="file-uploaded-info"
            >{{ uploadedFiles.get("uploadedFileCRLV")?.name }}</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 100%">
          <label for="laudoVistoria">Adicionar Laudo de Inspeção*</label>
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="laudoVistoria[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileLaudoVistoria')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="uploadedFiles.get('uploadedFileLaudoVistoria') !== null"
            class="file-uploaded-info"
            >{{ uploadedFiles.get("uploadedFileLaudoVistoria")?.name }}</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 100%">
          <label for="apoliceSeguro">Adicionar Apólice de Seguro*</label>
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="apoliceSeguro[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileApoliceSeguro')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="uploadedFiles.get('uploadedFileApoliceSeguro') !== null"
            class="file-uploaded-info"
            >{{ uploadedFiles.get("uploadedFileApoliceSeguro")?.name }}</small
          >
        </div>
      </div>

      <div
        class="form-row"
        *ngIf="
          condutoresList &&
          condutoresList.length > 0 &&
          formGroup.get('condutor')?.valid
        "
      >
        <div class="form-div" style="width: 100%">
          <label for="dpvat">Adicionar DPVAT*</label>
          <p-fileUpload
            mode="basic"
            chooseLabel="Clique aqui para adicionar (pdf)"
            name="dpvat[]"
            [customUpload]="true"
            accept="application/pdf"
            [maxFileSize]="5119998"
            [auto]="true"
            (uploadHandler)="onUpload($event, 'uploadedFileDPVAT')"
            chooseIcon="pi-upload"
            class="uploadButton"
          >
          </p-fileUpload>
          <small
            *ngIf="uploadedFiles.get('uploadedFileDPVAT') !== null"
            class="file-uploaded-info"
            >{{ uploadedFiles.get("uploadedFileDPVAT")?.name }}</small
          >
        </div>
      </div>

      <div class="form-footer">
        <p-button
          label="Voltar"
          styleClass="p-button-success custom-button"
          routerLink="/home"
        />

        <p-button
          label="Gravar"
          styleClass="p-button-info custom-button"
          (onClick)="complete()"
          icon="pi pi-angle-right"
          iconPos="right"
          *ngIf="
            condutoresList &&
            condutoresList.length > 0 &&
            formGroup.get('condutor')?.valid
          "
        />
      </div>
    </form>
  </div>
</div>
