<div style="width: 100%">
  <div class="content">
    <form [formGroup]="formGroup">
      <h3>Cadastro de Operadora</h3>

      <div class="form-row">
        <div class="form-div" style="width: 50%">
          <label for="tpSituacao">Situação</label>
          <input
            type="text"
            pInputText
            formControlName="tpSituacao"
            [value]="
              formGroup.get('tpSituacao')?.value == 'P'
                ? 'Pendente'
                : formGroup.get('tpSituacao')?.value == 'A'
                ? 'Aprovado'
                : formGroup.get('tpSituacao')?.value == 'R'
                ? 'Reprovado'
                : ''
            "
            class="input"
            required
            maxlength="20"
            [readonly]="true"
          />
        </div>
        <div class="form-div" style="width: 50%">
          <label for="dsObservacao">Observação</label>
          <input
            type="text"
            pInputText
            formControlName="dsObservacao"
            class="input"
            maxlength="50"
            [readonly]="true"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="nomefantasia">Nome Fantasia*</label>
          <input
            type="text"
            pInputText
            formControlName="nomefantasia"
            class="input"
            required
            maxlength="20"
          />
          <small
            *ngIf="
              (formGroup.get('nomefantasia').invalid && submitted) ||
              (formGroup.get('nomefantasia').dirty &&
                formGroup.get('nomefantasia').invalid)
            "
            class="p-error"
            >Nome Fantasia é Obrigatório.</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label for="razaosocial">Razão Social*</label>
          <input
            type="text"
            pInputText
            formControlName="razaosocial"
            class="input"
            required
            maxlength="60"
          />
          <small
            *ngIf="
              (formGroup.get('razaosocial').invalid && submitted) ||
              (formGroup.get('razaosocial').dirty &&
                formGroup.get('razaosocial').invalid)
            "
            class="p-error"
            >Razão Social é Obrigatório.</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 30%">
          <label for="cnpj">CNPJ*</label>
          <p-inputMask
            mask="99.999.999/9999-99"
            [unmask]="true"
            formControlName="cnpj"
            [readonly]="true"
          ></p-inputMask>
          <small
            *ngIf="
              (formGroup.get('cnpj').invalid && submitted) ||
              (formGroup.get('cnpj').dirty && formGroup.get('cnpj').invalid)
            "
            class="p-error"
            >CNPJ é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label>E-mail*</label>
          <input
            type="email"
            pInputText
            formControlName="email"
            class="input"
            required
            maxlength="100"
          />
          <small
            *ngIf="
              (formGroup.get('email').invalid && submitted) ||
              (formGroup.get('email').dirty && formGroup.get('email').invalid)
            "
            class="p-error"
            >E-mail é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label>Telefone*</label>
          <p-inputMask
            mask="(99)99999-9999"
            [unmask]="true"
            formControlName="telefone"
          ></p-inputMask>
          <small
            *ngIf="
              (formGroup.get('telefone').invalid && submitted) ||
              (formGroup.get('telefone').dirty &&
                formGroup.get('telefone').invalid)
            "
            class="p-error"
            >Telefone é Obrigatório.</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 30%">
          <label>Pessoa de Contato*</label>
          <input
            type="text"
            pInputText
            formControlName="pessoaContato"
            class="input"
            required
            maxlength="20"
          />
          <small
            *ngIf="
              (formGroup.get('pessoaContato').invalid && submitted) ||
              (formGroup.get('pessoaContato').dirty &&
                formGroup.get('pessoaContato').invalid)
            "
            class="p-error"
            >Pessoa de Contato é Obrigatório.</small
          >
        </div>
      </div>

      <h3>Endereço</h3>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label>Logradouro*</label>
          <input
            type="text"
            pInputText
            formControlName="logradouro"
            class="input"
            required
            maxlength="280"
          />
          <small
            *ngIf="
              (formGroup.get('logradouro').invalid && submitted) ||
              (formGroup.get('logradouro').dirty &&
                formGroup.get('logradouro').invalid)
            "
            class="p-error"
            >Logradouro é Obrigatório.</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 30%">
          <label>Bairro*</label>
          <input
            type="text"
            pInputText
            formControlName="bairro"
            class="input"
            required
            maxlength="100"
          />
          <small
            *ngIf="
              (formGroup.get('bairro').invalid && submitted) ||
              (formGroup.get('bairro').dirty && formGroup.get('bairro').invalid)
            "
            class="p-error"
            >Bairro é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label>Número*</label>
          <input
            type="number"
            pInputText
            formControlName="numero"
            class="input"
            required
            min="0"
            max="9999999999"
            maxlength="10"
          />
          <small
            *ngIf="
              (formGroup.get('numero').invalid && submitted) ||
              (formGroup.get('numero').dirty && formGroup.get('numero').invalid)
            "
            class="p-error"
            >Número é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label>CEP*</label>
          <div class="p-inputgroup">
            <p-inputMask
              mask="99999-999"
              formControlName="cep"
              [unmask]="true"
            ></p-inputMask>
            <p-button
              styleClass="p-button-warning cep-button"
              icon="pi pi-search"
              (onClick)="buscarEndereco()"
            ></p-button>
          </div>
          <small
            *ngIf="
              (formGroup.get('cep').invalid && submitted) ||
              (formGroup.get('cep').dirty && formGroup.get('cep').invalid)
            "
            class="p-error"
            >CEP é Obrigatório.</small
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 100%">
          <label>Complemento</label>
          <input
            type="text"
            pInputText
            formControlName="complemento"
            class="input"
            maxlength="50"
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-div" style="width: 30%">
          <label>Cidade*</label>
          <input
            type="text"
            pInputText
            formControlName="cidade"
            class="input"
            required
            maxlength="50"
          />
          <small
            *ngIf="
              (formGroup.get('cidade').invalid && submitted) ||
              (formGroup.get('cidade').dirty && formGroup.get('cidade').invalid)
            "
            class="p-error"
            >Cidade é Obrigatório.</small
          >
        </div>
        <div class="form-div" style="width: 30%">
          <label>Estado*</label>
          <p-dropdown
            placeholder="Selecione"
            autoWidth="false"
            [style]="{ width: '100%' }"
            [options]="estadoList"
            optionLabel="cdEstado"
            optionValue="cdEstado"
            formControlName="estado"
          ></p-dropdown>
          <small
            *ngIf="
              (formGroup.get('estado').invalid && submitted) ||
              (formGroup.get('estado').dirty && formGroup.get('estado').invalid)
            "
            class="p-error"
            >Estado é Obrigatório.</small
          >
        </div>
      </div>

      <div class="form-footer">
        <p-button
          label="Voltar"
          styleClass="p-button-success custom-button"
          routerLink="/home"
        />

        <p-button
          label="Avançar"
          styleClass="p-button-info custom-button"
          (onClick)="nextPage()"
          icon="pi pi-angle-right"
          iconPos="right"
          [disabled]="formGroup.invalid"
        />
      </div>
    </form>
  </div>
</div>

<app-endereco-dialog
  [estadoList]="estadoList"
  [displayDialog]="displayDialog"
  (enderecoSelecionado)="selecionarEndereco($event)"
></app-endereco-dialog>
