<div class="vertical-menu">
  <div class="menu-container">
    <div class="usuario-header">
      <img src="../../../assets/user_logo.png" alt="" />
      <span class="nome-menu">{{ obtemNomeUsuario() }}</span>      
    </div>

    <div class="menu-item" *ngFor="let item of menuItems" (click)="navigateTo(item.routerLink)" [class.active]="item.routerLink === activeMenuItem">
      <i class="menu-icon" [class]="item.iconClass"></i>
      <span class="menu-text">{{ item.text }}</span>
    </div>
  </div>
</div>
