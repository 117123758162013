import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { ReactiveFormsModule } from '@angular/forms';
import { VeiculosComponent } from './veiculos.component';
import { VeiculosRoutingModule } from './veiculos-routing.module';
import { VeiculosService } from './veiculos.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    VeiculosComponent
  ],
  imports: [
    CommonModule,
    VeiculosRoutingModule,
    DropdownModule,
    ToastModule,
    ButtonModule,
    FileUploadModule,
    InputMaskModule,
    ReactiveFormsModule,
    RadioButtonModule,
    InputNumberModule,
    CalendarModule
  ],
  bootstrap: [VeiculosComponent],
  providers: [ VeiculosService ]
})
export class VeiculosModule { }
