<p-toast></p-toast>
<div class="content">
  <h3>Operadoras</h3>
  <div class="lista-container" *ngIf="items && items.length > 0">
    <div class="selecionar-todos">
      <input
        type="checkbox"
        id="checkboxSelecionarTodos"
        (change)="selectAll()"
      />
      <label for="checkboxSelecionarTodos"></label>
      <label for="checkboxSelecionarTodos">Selecionar todos</label>
    </div>

    <div class="lista">
      <!-- Item 1 -->
      <div class="item" *ngFor="let item of items">
        <div class="info-container-left">
          <div>
            <input
              type="checkbox"
              [(ngModel)]="item.selected"
              class="custom-checkbox"
            />
          </div>
          <div class="linha-info">
            <span class="nome">Operadora:</span>
            <span
              class="nm-razao-social"
              (click)="abrirModalDeConsulta(item.oidCadastroOperadoras)"
              >{{ item.nmRazaoSocial }}</span
            >
            <span class="nome">CNPJ:</span>
            <span class="cnpj">{{ item.nrCnpj }}</span>
          </div>
        </div>

        <div class="icones">
          <div class="icone">
            <span
              class="pi pi-check-circle custom-icon icone-check"
              (click)="aprovarOperadoraSelecionada(item.oidCadastroOperadoras)"
            ></span>
          </div>
          <div class="icone">
            <span
              class="pi pi-times-circle custom-icon icone-times"
              (click)="removerOperadoraSelecionada(item.oidCadastroOperadoras)"
            ></span>
          </div>
          <div class="icone">
            <span
              class="pi pi-download custom-icon icone-download"
              (click)="baixarArquivos(item.oidCadastroOperadoras)"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <div class="linha-acoes">
      <p-button
        label="Aprovar Selecionados"
        (onClick)="aprovarSelecionados()"
        styleClass="p-button-success custom-button"
        icon="pi pi-check-circle"
        iconPos="left"
      />

      <p-button
        label="Remover Selecionados"
        (onClick)="removerSelecionados()"
        styleClass="p-button-danger custom-button"
        icon="pi pi-times-circle"
        iconPos="left"
      />
    </div>
  </div>
  <div
    class="linha-msg"
    style="width: 100%"
    *ngIf="!items || items.length === 0"
  >
    <p class="success">Não existem operadoras pendentes de aprovação</p>
  </div>
</div>

<app-dialog-operadora
  [oidCadastroOperadoras]="oidCadastroOperadorasSelecionado"
  [displayDialog]="displayDialog"
  (closeDialog)="closeDialog()"
  *ngIf="displayDialog && oidCadastroOperadorasSelecionado"
></app-dialog-operadora>

<app-dialog-motivo-reprovacao
  [inputData]="inputData"
  [displayDialog]="displayDialogMotivoReprovacao"
  (data)="onDialogMotivoClose($event)"
></app-dialog-motivo-reprovacao>
