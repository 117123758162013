import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CadastroOperadora } from 'src/app/model/cadastro-operadora.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EnvironmentService } from 'src/app/service/environment.service';
import { AprovacaoOperadora } from 'src/app/model/aprovacao-operadora.model';


@Injectable()
export class OperadoraService {

  private cadastroFinalizado = new Subject<any>();

  private formularioCadastro: {
    cadastroOperadora: CadastroOperadora;
    uploadedFileContratoSocial: File | null;
    uploadedFileComprovanteEndereco: File | null;
    uploadedFileInscricaoEstadual: File | null;
    uploadedFileProcuracao: File | null;
    uploadedFileCertidaoNegativa: File | null;
    uploadedFileCartaoCnpj: File | null;
    uploadedFileComprovanteRegularidadeInss: File | null;
    uploadedFileComprovanteRegularidadeFgts: File | null;
  };

  constructor(private httpClient: HttpClient, private env: EnvironmentService) {

    this.formularioCadastro = {
      cadastroOperadora: {
        oidCadastroOperadoras: null,
        nmFantasia: '',
        nmRazaoSocial: '',
        nrCnpj: null,
        dsEmail: '',
        nrTelefoneContato: null,
        nmContato: '',
        dsLogradouro: '',
        nmBairro: '',
        nrNroLogradouro: null,
        nrCep: '',
        dsComplemento: '',
        nmCidade: '',
        cdEstado: ''
      },
      uploadedFileContratoSocial: null,
      uploadedFileComprovanteEndereco: null,
      uploadedFileInscricaoEstadual: null,
      uploadedFileProcuracao: null,
      uploadedFileCertidaoNegativa: null,
      uploadedFileCartaoCnpj: null,
      uploadedFileComprovanteRegularidadeInss: null,
      uploadedFileComprovanteRegularidadeFgts: null,
    };
  }

  cadastroFinalizado$ = this.cadastroFinalizado.asObservable();

  getFormularioCadastro() {
    return this.formularioCadastro;
  }

  setFormularioCadastro(formularioCadastro) {
    this.formularioCadastro = formularioCadastro;
  }

  complete() {
    this.cadastroFinalizado.next(this.formularioCadastro.cadastroOperadora);
  }

  public create(cadastroOperadoraFormData: FormData): Observable<CadastroOperadora> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.post<CadastroOperadora>(this.env.getHostURL() + '/operadora', cadastroOperadoraFormData, { headers });
  }

  public update(cadastroOperadoraFormData: FormData): Observable<CadastroOperadora> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.put<CadastroOperadora>(this.env.getHostURL() + '/operadora', cadastroOperadoraFormData, { headers });
  }

  public findOperadoraByCnpj(cnpj: string): Observable<CadastroOperadora> {
    console.log(cnpj);
    const retorno = this.httpClient.get<CadastroOperadora>(`${this.env.getHostURL()}/operadora/cnpj/${cnpj}`);
    console.log(retorno);
    return retorno;
  }

  public findOperadoraById(oidCadastroOperadoras: number): Observable<CadastroOperadora> {
    const retorno = this.httpClient.get<CadastroOperadora>(`${this.env.getHostURL()}/operadora/buscar-por-id/${oidCadastroOperadoras}`);
    return retorno;
  }

  public findSituacaoCadastroOperadoraByCnpj(cnpj: string): Observable<string> {
    console.log(cnpj);
    const options = { responseType: 'text' as 'json' };
    const retorno = this.httpClient.get<string>(`${this.env.getHostURL()}/operadora/cnpj/${cnpj}/situacao-cadastro`, options);
    console.log(retorno);
    return retorno;
  }

  public uploadPrestacaoContas(uploadPrestacaoContasFormData: FormData): Observable<void> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.post<void>(this.env.getHostURL() + '/operadora/upload/prestacao-contas', uploadPrestacaoContasFormData, { headers });
  
  }

}