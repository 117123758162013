import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, map, take } from 'rxjs';
import { GlobalVarsService } from './service/global-vars.service';

@Injectable({
  providedIn: 'root',
})
class AuthGuard {
  constructor(
    private router: Router,
    private globalVarsService: GlobalVarsService
  ) {}

  isAdmin(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.globalVarsService.isUsuarioLogado().pipe(
      take(1),
      map((isUsuarioLogado: boolean) => {
        const idPerfil = localStorage.getItem('idPerfil');

        if (!isUsuarioLogado) {
          this.globalVarsService.limparStorage();
          this.router.navigate(['/login']);
          return false;
        } else if (idPerfil != 'PMU') {
          this.router.navigate(['/home']);
          return false;
        }

        return true;
      })
    );
  }
}

export const adminGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AuthGuard).isAdmin(next, state);
};
