import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IUsuarioLogado } from '../model/usuario';

@Injectable({
  providedIn: 'root',
})
export class GlobalVarsService {
  private USER_INFO = 'userInfo';
  private TOKEN = 'token';
  private MOSTRAR_ATALHO_DIALOG = 'mostrarAtalhoDialog';
  private ID_ATUALIZA_DADOS = 'idAtualizaDados';
  private DS_MSG_ATUALIZA_DADOS = 'dsMsgAtualizacaoCadastro';
  private readonly STORAGE_USUARIO_LOGADO = 'usuarioLogado';
  private readonly STORAGE_PERFIL = 'idPerfil';
  private usuarioLogadoObservable: BehaviorSubject<IUsuarioLogado>;

  constructor() {}

  setUserInfo(userInfo: any): void {
    localStorage.setItem(this.USER_INFO, JSON.stringify(userInfo));
  }

  getUserInfo() {
    const usuario = localStorage.getItem(this.USER_INFO);
    return usuario == null ? null : JSON.parse(usuario);
  }

  setToken(token: any): void {
    localStorage.setItem(this.TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(this.TOKEN);
  }

  setMostrarAtalhoDialog(mostrar: any): void {
    localStorage.setItem(this.MOSTRAR_ATALHO_DIALOG, mostrar);
  }

  getMostrarAtalhoDialog(): boolean {
    return localStorage.getItem(this.MOSTRAR_ATALHO_DIALOG) != 'false';
  }

  getUserCnpj() {
    return this.getUserInfo()
      ? this.getUserInfo().cnpj.padStart(14, '0')
      : null;
  }

  limparStorage(): void {
    localStorage.clear();
  }

  public isUsuarioLogado(): Observable<boolean> {
    let usuario = this.getUserInfo();
    return new BehaviorSubject<boolean>(
      usuario && new Date() <= new Date(usuario.exp)
    ).asObservable();
  }

  setIdAtualizaDados(idAtualizaDados: string): void {
    localStorage.setItem(this.ID_ATUALIZA_DADOS, idAtualizaDados);
  }

  getIdAtualizaDados() {
    return localStorage.getItem(this.ID_ATUALIZA_DADOS);
  }

  setDsMsgAtualizacaoCadastro(idAtualizaDados: string): void {
    localStorage.setItem(this.DS_MSG_ATUALIZA_DADOS, idAtualizaDados);
  }

  getDsMsgAtualizacaoCadastro() {
    return localStorage.getItem(this.DS_MSG_ATUALIZA_DADOS);
  }

  public setUsuarioLogado(usuario: IUsuarioLogado) {
    localStorage.setItem(this.STORAGE_USUARIO_LOGADO, JSON.stringify(usuario));
    this.usuarioLogadoObservable.next(usuario);
  }

  public setPerfil(perfil: string) {
    localStorage.setItem(this.STORAGE_PERFIL, perfil);
  }
}
