<p-dialog
  header=""
  [(visible)]="displayDialog"
  [modal]="true"
  [draggable]="false"
  [breakpoints]="{ '960px': '80vw', '640px': '100vw' }"
  [style]="{ width: '80vw' }"
  (onHide)="clearDialog()"
>
  <div style="width: 100%">
    <div class="content">
      <form ngNoForm>
        <h3>Motivo Reprovação</h3>
        <input
          type="text"
          pInputText
          [(ngModel)]="dsMotivoReprovacao"
          required
        />
        <div class="btns">
          <p-button
            label="Cancelar"
            styleClass="p-button custom-button"
            (onClick)="displayDialog = false"
          />
          <p-button
            label="Reprovar"
            [disabled]="
              !dsMotivoReprovacao || dsMotivoReprovacao.trim().length == 0
            "
            styleClass="p-button-danger custom-button"
            icon="pi pi-times-circle"
            iconPos="left"
            (onClick)="onRemove()"
          />
        </div>
      </form>
    </div>
  </div>
</p-dialog>
