export class Localizacao {

    constructor(
      public cdEstado: string,
      public cdCidade: string,
      public cep: string,
      public cliente: string,
      public endereco: string,
      public nmBairro: string,
      public nmCidade: string,
      public nmLogradouro: string,
      public nrLogradouroFinal: number,
      public nrLogradouroInicial: number,
      public tpLogradouro: string,
      public tpNumeracaoCep: string
      ) {
    }
  
  }
  