import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnderecoService } from 'src/app/service/endereco.service';
import { CondutoresService } from '../condutores.service';
import { CadastroCondutores } from 'src/app/model/cadastro-condutor.model';
import { Localizacao } from 'src/app/model/localizacao.model';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { catchError, debounceTime, finalize } from 'rxjs';
import { DatePipe } from '@angular/common';
import { cnhDateValidator } from 'src/app/shared/utils/custom-validators';

@Component({
  selector: 'app-condutores-dados',
  templateUrl: './condutores-dados.component.html',
  styleUrls: ['./condutores-dados.component.scss'],
})
export class CondutoresDadosComponent implements OnInit {
  public formGroup: FormGroup;
  estadoList: any[] = [];
  submitted: boolean = false;
  cadastroCondutores: CadastroCondutores;
  localizacoes: Localizacao[];
  displayDialog: boolean = false;
  oldCpf: string = '';

  categoriasCnh = ['AB', 'A', 'B', 'C', 'D', 'E'];

  constructor(
    private formBuilder: FormBuilder,
    private enderecoService: EnderecoService,
    private condutoresService: CondutoresService,
    private messageService: MessageService,
    private router: Router,
    private globalVarsService: GlobalVarsService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.initializeFormGroup();
    this.initializeData();
  }

  private initializeData() {
    this.getEstados();
    this.cadastroCondutores =
      this.condutoresService.getFormularioCadastro()?.cadastroCondutor;
  }

  onCpfChange(newValue: any) {
    const newCpf = this.formGroup.get('nrCpf').value;

    this.formGroup.get('nrCpf').markAsTouched();

    if (newCpf !== undefined && newCpf.length == 11 && newCpf !== this.oldCpf) {
      this.findCondutorByCPF(newCpf);
    }
  }

  private findCondutorByCPF(cpf: string) {
    this.spinner.show();

    this.condutoresService
      .findCondutorByCPF(cpf)
      .pipe(
        catchError((error) => {
          console.error('Erro ao buscar condutor:', error);
          this.messageService.add({
            severity: 'Error',
            summary: 'Erro ao recuperar condutor',
            detail: error,
          });
          throw error;
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((data) => {
        if (data && data.oidCadastroCondutor) {
          data.dtVencimentoCnh = this.convertDataFormat(data.dtVencimentoCnh);
          data.dtNascimento = this.convertDataFormat(data.dtNascimento);

          this.condutoresService.getFormularioCadastro().cadastroCondutor =
            data;
          this.cadastroCondutores =
            this.condutoresService.getFormularioCadastro()?.cadastroCondutor;
          this.oldCpf = this.cadastroCondutores.nrCpf;
          this.patchFormValues();
          this.messageService.add({
            severity: 'success',
            summary: 'Condutor',
            detail: 'Cadastro de condutor recuperado com sucesso!',
          });
          console.log('Cadastro de condutor recuperado com sucesso.');
        }
      });
  }

  convertDataFormat(data: Date): Date {
    const datePipe = new DatePipe('en-US');
    const dataFormatada = data;
    const dataParaExibir = datePipe.transform(dataFormatada, 'yyyy-MM-dd');
    return new Date(dataParaExibir);
  }

  private patchFormValues() {
    this.formGroup.patchValue({
      oidCadastroCondutor: this.cadastroCondutores.oidCadastroCondutor,
      nmCondutor: this.cadastroCondutores.nmCondutor,
      nrCpf: this.cadastroCondutores.nrCpf,
      nrDocumIdentid: this.cadastroCondutores.nrDocumIdentid,
      nmOrgaoEmIdent: this.cadastroCondutores.nmOrgaoEmIdent,
      nrCnh: this.cadastroCondutores.nrCnh,
      dsCategoriaCnh: this.cadastroCondutores.dsCategoriaCnh,
      dsExpedidorCnh: this.cadastroCondutores.dsExpedidorCnh,
      dtVencimentoCnh: this.cadastroCondutores.dtVencimentoCnh,
      dsEmail: this.cadastroCondutores.dsEmail,
      nrTelefoneContato: this.cadastroCondutores.nrTelefoneContato,
      dtNascimento: this.cadastroCondutores.dtNascimento,
      dsLogradouro: this.cadastroCondutores.dsLogradouro,
      nmBairro: this.cadastroCondutores.nmBairro,
      nrNroLogradouro: this.cadastroCondutores.nrNroLogradouro,
      nrCep: this.cadastroCondutores.nrCep,
      dsComplemento: this.cadastroCondutores.dsComplemento,
      nmCidade: this.cadastroCondutores.nmCidade,
      cdEstado: this.cadastroCondutores.cdEstado,
    });
  }

  private initializeFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      nmCondutor: ['', [Validators.required, Validators.maxLength(100)]],
      nrCpf: ['', [Validators.required, Validators.maxLength(11)]],
      nrDocumIdentid: ['', [Validators.required, Validators.maxLength(20)]],
      nmOrgaoEmIdent: ['', [Validators.required, Validators.maxLength(8)]],
      nrCnh: ['', [Validators.required, Validators.maxLength(15)]],
      dsCategoriaCnh: [null, [Validators.required]],
      dsExpedidorCnh: ['', [Validators.required, Validators.maxLength(10)]],
      dtVencimentoCnh: [null, [Validators.required, cnhDateValidator()]],
      dsEmail: ['', [Validators.required, Validators.maxLength(100)]],
      nrTelefoneContato: ['', [Validators.required, Validators.maxLength(15)]],
      dtNascimento: [null, [Validators.required]],
      dsLogradouro: ['', [Validators.required, Validators.maxLength(100)]],
      nmBairro: ['', [Validators.required, Validators.maxLength(100)]],
      nrNroLogradouro: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.min(0),
          Validators.max(999999999),
        ],
      ],
      nrCep: ['', [Validators.required, Validators.maxLength(100)]],
      dsComplemento: ['', [Validators.maxLength(50)]],
      nmCidade: ['', [Validators.maxLength(50)]],
      cdEstado: ['', [Validators.required]],
    });
  }

  getUserCNPJ(): string {
    return this.globalVarsService.getUserInfo()?.cnpj;
  }

  getEstados() {
    this.spinner.show();

    this.enderecoService.findEstados().subscribe(
      (data) => {
        this.estadoList = data;
      },
      (error) => {
        console.error('Erro ao buscar estados:', error);
        this.messageService.add({
          severity: 'Error',
          summary: 'Erro ao recuperar estados',
          detail: error,
        });
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  copiarDadosParaCadastroCondutores() {
    const formValue = this.formGroup.value;

    this.cadastroCondutores.nmCondutor = formValue.nmCondutor;
    this.cadastroCondutores.nrCpf = formValue.nrCpf;
    this.cadastroCondutores.nrDocumIdentid = formValue.nrDocumIdentid;
    this.cadastroCondutores.nmOrgaoEmIdent = formValue.nmOrgaoEmIdent;
    this.cadastroCondutores.nrCnh = formValue.nrCnh;
    this.cadastroCondutores.dsExpedidorCnh = formValue.dsExpedidorCnh;
    this.cadastroCondutores.dtVencimentoCnh = formValue.dtVencimentoCnh;
    this.cadastroCondutores.dsEmail = formValue.dsEmail;
    this.cadastroCondutores.nrTelefoneContato = formValue.nrTelefoneContato;
    this.cadastroCondutores.dtNascimento = formValue.dtNascimento;
    this.cadastroCondutores.dsCategoriaCnh = formValue.dsCategoriaCnh;
    this.cadastroCondutores.dsLogradouro = formValue.dsLogradouro;
    this.cadastroCondutores.nmBairro = formValue.nmBairro;
    this.cadastroCondutores.nrNroLogradouro = formValue.nrNroLogradouro;
    this.cadastroCondutores.nrCep = formValue.nrCep;
    this.cadastroCondutores.dsComplemento = formValue.dsComplemento;
    this.cadastroCondutores.nmCidade = formValue.nmCidade;
    this.cadastroCondutores.cdEstado = formValue.cdEstado;
  }

  nextPage() {
    if (this.formGroup.valid) {
      this.copiarDadosParaCadastroCondutores();
      this.configuraSituacaoCadastro();
      this.router.navigate(['condutores/documentos']);
      return;
    }

    this.submitted = true;
  }

  configuraSituacaoCadastro(): void {
    if (this.cadastroCondutores.oidCadastroCondutor == null) {
      this.cadastroCondutores.situacaoCadastro = 'P';
    }
  }

  buscarEndereco() {
    const cep: string = this.formGroup.get('nrCep').value;
    if (cep != null && cep.length == 8) {
      this.spinner.show();

      this.enderecoService.findPorCep(cep).subscribe(
        (data) => {
          this.localizacoes = data;
          if (this.localizacoes.length > 1) this.displayDialog = true;
          if (this.localizacoes.length == 1)
            this.selecionarEndereco(this.localizacoes[0]);
          if (this.localizacoes.length == 0) {
            this.displayDialog = true;
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail: `CEP não localizado!`,
            });
          }
        },
        (error) => {
          this.displayDialog = true;
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail: `CEP não localizado!`,
          });
        },
        () => {
          this.spinner.hide();
        }
      );
    } else {
      this.messageService.add({
        severity: 'info',
        summary: 'Buscar CEP',
        detail: 'O campo CEP deve estar preenchido para fazer a busca',
      });
    }
  }

  selecionarEndereco(localizacao: any) {
    const endereco: Localizacao = new Localizacao(
      localizacao.cdEstado,
      localizacao.cdCidade,
      localizacao.cep,
      localizacao.cliente,
      localizacao.endereco,
      localizacao.nmBairro,
      localizacao.nmCidade,
      localizacao.nmLogradouro,
      localizacao.nrLogradouroFinal,
      localizacao.nrLogradouroInicial,
      localizacao.tpLogradouro,
      localizacao.tpNumeracaoCep
    );

    this.formGroup.get('dsLogradouro').setValue(endereco.endereco);
    this.formGroup.get('nmBairro').setValue(endereco.nmBairro);
    this.formGroup.get('nrNroLogradouro').setValue(null);
    this.formGroup.get('dsComplemento').setValue('');
    this.formGroup.get('nmCidade').setValue(endereco.nmCidade);
    this.formGroup.get('nrCep').setValue(endereco.cep);

    const estadoSelecionado = this.estadoList.find(
      (item) => item.cdEstado === endereco.cdEstado
    );

    if (estadoSelecionado) {
      this.formGroup.get('cdEstado').setValue(estadoSelecionado.cdEstado);
    }

    this.displayDialog = false;
  }

  caixaAlta(fieldName: string) {
    const campo = this.formGroup.get(fieldName);

    if (campo && campo.value) {
      campo.setValue(campo.value.toUpperCase());
    }
  }
}
