<p-dialog header="" [(visible)]="displayDialog" [modal]="true" [draggable]="false"
    [breakpoints]="{ '960px': '80vw', '640px': '100vw' }" [style]="{ width: '80vw' }" (onHide)="limpaDialog()">
<div style="width: 100%">
    <div class="content">
        <form ngNoForm>
            <h3>Cadastro do Condutor</h3>

            <div class="form-row" *ngIf="oidCadastroCondutor">
                <div class="form-div" style="width: 50%">
                    <label for="nmCondutor">Situação</label>
                    <input type="text" pInputText [value]="
                    cadastroCondutor?.tpSituacao == 'P'
                      ? 'Pendente'
                      : cadastroCondutor?.tpSituacao == 'A'
                      ? 'Aprovado'
                      : cadastroCondutor?.tpSituacao == 'R'
                      ? 'Reprovado'
                      : ''
                  " class="input" required maxlength="20" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 50%">
                    <label for="nmCondutor">Observação</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.dsObservacao" class="input" required maxlength="50" [readonly]="true" />
                </div>
            </div>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="nmCondutor">Nome Completo</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.nmCondutor" class="input" required maxlength="20" [readonly]="true" />
                </div>
            </div>

            <div class="form-row">
                
                <div class="form-div" style="width: 30%">
                    <label for="nrCpf" >CPF</label>
                    <p-inputMask mask="999.999.999-99" [unmask]="true" [(ngModel)]="cadastroCondutor.nrCpf" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nrDocumIdentid">RG</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.nrDocumIdentid" class="input" required maxlength="20" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nmOrgaoEmIdent">Órgão Expedidor</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.nmOrgaoEmIdent" class="input" required maxlength="8" [readonly]="true" />
                </div>
                
            </div>

            <div class="form-row">
                
                <div class="form-div" style="width: 30%">
                    <label for="nrCnh" >CNH</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.nrCnh" class="input" required maxlength="15" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="dsExpedidorCnh">Órgão Expedidor</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.dsExpedidorCnh" class="input" required maxlength="10" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="dtVencimentoCnh">Data de Validade</label>                    
                    <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="cadastroCondutor.dtVencimentoCnh" [required]="true" [disabled]="true" />
                </div>
                
            </div>      
            
            <div class="form-row">
                
                <div class="form-div" style="width: 30%">
                    <label for="dtNascimento">Data de Nascimento</label>                    
                    <p-calendar dateFormat="dd/mm/yy" [(ngModel)]="cadastroCondutor.dtNascimento" [required]="true" [disabled]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="dsEmail">E-mail</label>
                    <input type="dsEmail" pInputText [(ngModel)]="cadastroCondutor.dsEmail" class="input" required maxlength="100" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nrTelefoneContato">Telefone Contato</label>
                    <p-inputMask mask="(99)99999-9999" [unmask]="true" [(ngModel)]="cadastroCondutor.nrTelefoneContato" [readonly]="true" />
                </div>
                
            </div>                

            <h3>Endereço</h3>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="dsLogradouro">Logradouro</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.dsLogradouro" class="input" required maxlength="280" [readonly]="true" />
                </div>
            </div>

            <div class="form-row">
                <div class="form-div" style="width: 30%">
                    <label>Bairro</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.nmBairro" class="input" required maxlength="100" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label for="nrNroLogradouro">Número</label>
                    <input type="number" pInputText [(ngModel)]="cadastroCondutor.nrNroLogradouro" class="input" required min="0" max="9999999999" maxlength="10" [readonly]="true" />
                </div>                
                <div class="form-div" style="width: 30%;">
                    <label for="nrCep">CEP</label>                    
                        <p-inputMask mask="99999-999" [(ngModel)]="cadastroCondutor.nrCep" [unmask]="true" [readonly]="true" [readonly]="true" />
                </div>             
                
            </div>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="dsComplemento">Complemento</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.dsComplemento" class="input" maxlength="50" [readonly]="true" />
                </div>
            </div>

            <div class="form-row">
                <div class="form-div" style="width: 30%">
                    <label for="nmCidade">Cidade</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.nmCidade" class="input" required maxlength="50" [readonly]="true" />
                </div>
                <div class="form-div" style="width: 30%">
                    <label>Estado</label>
                    <input type="text" pInputText [(ngModel)]="cadastroCondutor.cdEstado" class="input" required maxlength="50"
                            [readonly]="true" />
                </div>
            </div>

        </form>
    </div>
</div>
</p-dialog>