import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-btn-topo',
  templateUrl: './btn-topo.component.html',
  styleUrls: ['./btn-topo.component.scss']
})
export class BtnTopoComponent implements OnInit {
  showbtn!: boolean;
  constructor() { }

  ngOnInit(): void {
    const mostrarBtn = () => {
      this.showbtn = window.scrollY === 0 ? false : true
    }
    mostrarBtn()
    window.addEventListener('scroll', mostrarBtn)
  }

  voltarAoTopo(): void {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

}
