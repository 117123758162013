import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  constructor(public router: Router, private loginService: LoginService) {}

  acessoPessoaJuridica() {
    let urlCorrente = window.location.href;
    var jsonAssinador =
      'https://assinador-hml.uberlandia.mg.gov.br:8001/autenticar?nomeSistema=CredenciamentoOperadora';
    if (urlCorrente.indexOf('-hml') == -1) {
      jsonAssinador =
        'https://assinador.uberlandia.mg.gov.br:8001/autenticar?nomeSistema=CredenciamentoOperadora';
    }
    fetch(jsonAssinador)
      .then((res) => res.text())
      .then((response) => {
        let retornoJson = JSON.parse(response);
        console.log(retornoJson);
        let dsCpfCnpjPessoaCert = retornoJson?.dsCpfCnpjPessoaCert;
        if (dsCpfCnpjPessoaCert) {
          const informacoesUsuario = {
            cnpj: dsCpfCnpjPessoaCert,
            nmUsuario: dsCpfCnpjPessoaCert,
            dsEmail: '',
            nrTelefone: '',
            nrCelular: '',
            dtNascimento: null,
            oidUsuario: null,
            exp: new Date(new Date().getTime() + 60 * 60 * 1000),
          };
          this.loginService.login(informacoesUsuario);
          return;
        } else {
          alert(
            'Certificado INVÁLIDO ou não foi encontrado na máquina. Verifique!'
          );
        }
      })
      .catch(function (error) {
        alert(
          'Certificado INVÁLIDO ou não foi encontrado na máquina. Verifique!'
        );
        console.log('Erro: ' + error);
      });
  }

  
  /* acessoPessoaJuridica() {

    const informacoesUsuario = {
      cnpj: "47960950000121",
      nmUsuario: "47960950000121",
      dsEmail: "",
      nrTelefone: "",
      nrCelular: "",
      dtNascimento: null,
      oidUsuario: null,
      exp: new Date(new Date().getTime() + (60 * 60 * 1000))
    }

    this.loginService.login(informacoesUsuario);
    return;
  } */
}
