import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs';
import { CadastroCondutores } from 'src/app/model/cadastro-condutor.model';
import { CondutoresService } from 'src/app/page/condutores/condutores.service';
import { DatePipe } from '@angular/common';
import { ICondutor } from 'src/app/model/condutor';

@Component({
  selector: 'app-dialog-condutor',
  templateUrl: './dialog-condutor.component.html',
  styleUrls: ['./dialog-condutor.component.scss']
})
export class DialogCondutorComponent implements OnInit{
  
  @Input() displayDialog: boolean;
  @Input() oidCadastroCondutor: number;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  cadastroCondutor: CadastroCondutores = {
    oidCadastroCondutor: null,
    nmCondutor: '',
    nrCpf: '',
    nrDocumIdentid: '',
    nmOrgaoEmIdent: '',
    nrCnh: '',
    dsExpedidorCnh: '',
    dtVencimentoCnh: null,
    dsEmail: '',
    nrTelefoneContato: null,
    dtNascimento: null,
    dsLogradouro: '',
    nmBairro: '',
    nrNroLogradouro: null,
    nrCep: '',
    dsComplemento: '',
    nmCidade: '',
    cdEstado: '',
    situacaoCadastro: '',
    dsUsuAlter: '',
    vsVersao: null,
    uploadedFileCNH: '',
    uploadedFileComprovanteEndereco: '',
  };

  constructor(
    private condutoresServices: CondutoresService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.initializeData();
  }

  private initializeData() {
      this.findCondutorById();  
  }

  private findCondutorById() {
    if(this.oidCadastroCondutor){
      this.spinner.show(); 
  
      this.condutoresServices.findCondutorById(this.oidCadastroCondutor).pipe(
        catchError((error) => {
          console.error('Erro ao buscar condutor:', error);       
          throw error;
        }),
        finalize(() => {                
          this.spinner.hide(); 
        })
      ).subscribe((data) => {
        if (data && data.oidCadastroCondutor) {
          console.log(data);
          data.dtVencimentoCnh = this.convertDataFormat(data.dtVencimentoCnh); 
          data.dtNascimento = this.convertDataFormat(data.dtNascimento); 
          this.cadastroCondutor = data;
          console.log('Operação de busca concluída com sucesso.');
        }
      });
    }
    }

    convertDataFormat(data: Date): Date {
      const datePipe = new DatePipe('en-US');
      const dataFormatada = data;
          const dataParaExibir = datePipe.transform(dataFormatada, 'yyyy-MM-dd');
          return new Date(dataParaExibir);
    }

    limpaDialog() {
      this.closeDialog.emit(); 
    }
}
