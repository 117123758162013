import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CadastroVeiculos } from 'src/app/model/cadastro-veiculo.model';
import { VeiculosService } from '../veiculos/veiculos.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs';
import { CadastroCondutores } from 'src/app/model/cadastro-condutor.model';
import { CondutoresService } from '../condutores/condutores.service';
import { FileUploadEvent } from 'primeng/fileupload';
import { DatePipe } from '@angular/common';
import { OperadoraService } from '../operadora/operadora.service';

@Component({
  selector: 'app-relatorios',
  templateUrl: './relatorios.component.html',
  styleUrls: ['./relatorios.component.scss']
})
export class RelatoriosComponent implements OnInit{
  
  public formGroup: FormGroup;
  uploadedFiles: Map<string, File | null> = new Map<string, File | null>();

  constructor(
    private operadoraService: OperadoraService,
    private messageService: MessageService,
    private globalVarsService: GlobalVarsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.initializeData();
  } 

  private initializeData(){
    this.uploadedFiles.set('uploadedFilePrestacaoContas', null);
  }

  onUpload(event: any, fieldName: string) {
    if (event.files.length > 0) {
      this.uploadedFiles.set(fieldName, event.files[0]);
      this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: event.files[0].name });
    } else {
      this.uploadedFiles.set(fieldName, null);
    }
  }

  private getFormData(): FormData {
    const formData = new FormData();
    formData.append('nrCnpjOperadora', this.getUserCNPJ() || '');

    this.uploadedFiles.forEach((file, fieldName) => {
      if (file) {
        formData.append(fieldName, file, file.name);
      }
    });
  
    return formData;
  }  

  private validaFormularioCadastro(): boolean {

      const requiredFields = [
        'uploadedFilePrestacaoContas'
      ];
  
      for (const fieldName of requiredFields) {
        if (!this.uploadedFiles.get(fieldName)) {
          this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Todos documentos são obrigatórios!' });
          return false;
        }
      }
      return true;
  }

  getUserCNPJ(): string {
    return this.globalVarsService.getUserInfo()?.cnpj;
  }


  complete() {
    if(this.validaFormularioCadastro){
      this.processarCadastroVeiculo();
    }
  }  

  private processarCadastroVeiculo() {
    this.spinner.show();
    
    const formData: FormData = this.getFormData();
  
    this.operadoraService.uploadPrestacaoContas(formData).subscribe(
      () => {
        this.messageService.add({ severity: 'success', summary: 'Upload', detail: 'Upload efetuado com sucesso!' });
      },
      (error) => {
        console.error('Erro ao efetuar upload de arquivo', error);
        this.messageService.add({ severity: 'error', summary: 'Erro!', detail: 'Erro ao efetuar upload do arquivo!' });
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }
  

}