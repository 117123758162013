import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginRequest } from '../model/login-request-model';
import { EnvironmentService } from './environment.service';
import { GlobalVarsService } from './global-vars.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private globalVarsService: GlobalVarsService,
    private router: Router,
    private httpClient: HttpClient,
    private env: EnvironmentService
  ) {}

  login(informacoesUsuario: any) {
    const loginRequest: LoginRequest = {
      oidSistemas: JSON.stringify(environment.oidSistema),
      username: informacoesUsuario.cnpj,
      password: 'Prodaub1',
    };

    this.loginEAutoriza(loginRequest).subscribe((response) => {
      if (response.sucesso) {
        this.globalVarsService.setUserInfo(informacoesUsuario);
        localStorage.removeItem('idPerfil');
        this.globalVarsService.setToken(response.token);
        this.router.navigate(['/home']);
      } else {
        localStorage.clear();
      }
    });
  }

  buscarDadosUsuarioECnpj(nrCnpj: any): Observable<any> {
    return this.httpClient.get<any>(
      `${this.env.getHostURLEAutoriza()}/usuarios/nrCpf/${nrCnpj}`
    );
  }

  logout() {
    this.globalVarsService.limparStorage();
    this.router.navigate(['/login']);
  }

  public loginEAutoriza(login: any): Observable<any> {
    return this.httpClient.post(
      this.env.getHostURLEAutoriza() + '/login-ativo',
      // this.env.getHostURLEAutoriza() + environment.login.login,
      login
    );
  }
}
