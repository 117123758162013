import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from './environment.service';

@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    localStorage.getItem('token') != null
  );
  private perfil: BehaviorSubject<string> = new BehaviorSubject<string>(
    localStorage.getItem('usuarioLogado') != null
      ? this.verificaPerfil(
          JSON.parse(localStorage.getItem('usuarioLogado')).menus
        )
      : ''
  );

  constructor(private router: Router, private env: EnvironmentService) {}

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  public setIsLoggedIn() {
    this.loggedIn.next(true);
    this.router.navigate(['/']);
  }

  get nmPerfil() {
    return this.perfil.asObservable();
  }

  login(result: any) {
    localStorage.clear();
    localStorage.setItem('token', result.token);
    localStorage.setItem('idPerfil', 'PMU');
    localStorage.setItem(
      'usuarioLogado',
      JSON.stringify(result.informacoesUsuario)
    );
    const nmPerfil: string = this.verificaPerfil(
      result.informacoesUsuario.menus
    );
    this.perfil.next(nmPerfil);
    this.setIsLoggedIn();

    this.router.navigate(['/menu']);
  }

  cleanData() {
    localStorage.clear();
    this.loggedIn.next(false);
  }

  logout() {
    this.cleanData();
    window.location.replace(
      this.env.getHostURLEAutorizaWeb() +
        '/logout?oidSistemas=' +
        this.env.oidSistema()
    );
  }

  // A = Administrador
  // C = Comum
  private verificaPerfil(menus: any[]): string {
    let perfil = 'C';
    if (menus != null) {
      for (const menu of menus) {
        // TODO verificar se existirá perfil admin
        if (menu.nmMenu.includes('historicos-medicos/atualizar-status')) {
          perfil = 'A';
          break;
        }
      }
    }

    return perfil;
  }
}
