<div class="container">
  <div class="header">
    <div>
      <img src="../../../assets/Grupo 10520.png" alt="LOGO" />
    </div>
  </div>

  <div class="content">
    <div class="login-card">
      <form class="form" [formGroup]="loginForm">
        <h2>Acesse sua conta</h2>
        <div class="input-width">
          <label for="login">CPF</label>
          <p-inputMask
            mask="999.999.999-99"
            formControlName="login"
            [required]="true"
            [unmask]="true"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
          ></p-inputMask>
        </div>
        <div class="input-width">
          <label for="senha">Senha</label>
          <p-password
            formControlName="senha"
            [required]="true"
            [feedback]="false"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
          ></p-password>
          <!-- <mat-icon (click)="show = !show">{{
            show ? "visibility_off" : "visibility"
          }}</mat-icon> -->
        </div>
        <div class="complemento-login">
          <div class="checkbox">
            <p-checkbox
              formControlName="manterconectado"
              [binary]="true"
              inputId="binary"
            ></p-checkbox>
            <label for="binary">Manter Conectado</label>
          </div>
          <a [href]="esqueceuSenha">Esqueceu sua senha?</a>
        </div>
        <!-- <button
          class="btn-login"
          (click)="login()"
          mat-button
          color="default"
          [disabled]="!loginForm.valid"
        >
          Entrar
        </button> -->
        <div class="btn-div">
          <p-button
            label="Entrar"
            [disabled]="!loginForm.valid"
            (click)="login()"
            class="btn"
          ></p-button>
        </div>
        <!-- <div class="primeiro-acesso">
          <img src="../../assets/first-access-icon.png" />
          <span
            >Primeiro acesso?
            <a routerLink="/dados-contribuinte"> Crie sua conta</a></span
          >
        </div> -->
      </form>
    </div>

    <div class="app">
      <div>
        <!-- <img
          class="app-img"
          src="../../../../assets/LOGO_DEC.png"
          alt="credenciamento_logo"
        /> -->
        <span class="temp-logo">Cadastro de Operadora</span>
      </div>

      <div class="app-info">
        <span>Bem vindo(a)</span>

        <!-- <span
          >Acesse já a sua caixa postal eletrônica e fique ciente de todas as
          comunicações entre a Prefeitura de Uberlândia e sua empresa.</span
        > -->
      </div>
    </div>
  </div>
</div>
