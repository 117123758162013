import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CadastroCondutores } from 'src/app/model/cadastro-condutor.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from 'src/app/service/environment.service';

@Injectable()
export class CondutoresService {
  private cadastroFinalizado = new Subject<any>();

  private requestMapping: string = `${this.env.getHostURL()}/condutor`;

  private formularioCadastro: {
    cadastroCondutor: CadastroCondutores;
    uploadedFileCNH: File | null;
    uploadedFileComprovanteEndereco: File | null;
    uploadedFileInscricaoInss: File | null;
    uploadedFileTermoCompromisso: File | null;
    uploadedFileCertidaoNegativaCriminal: File | null;
    uploadedFileAtestadoAntecedentesCriminais: File | null;
  };

  constructor(private httpClient: HttpClient, private env: EnvironmentService) {
    this.formularioCadastro = {
      cadastroCondutor: {
        oidCadastroCondutor: null,
        nmCondutor: '',
        nrCpf: '',
        nrDocumIdentid: '',
        nmOrgaoEmIdent: '',
        nrCnh: '',
        dsCategoriaCnh: '',
        dsExpedidorCnh: '',
        dtVencimentoCnh: null,
        dsEmail: '',
        nrTelefoneContato: null,
        dtNascimento: null,
        dsLogradouro: '',
        nmBairro: '',
        nrNroLogradouro: null,
        nrCep: '',
        dsComplemento: '',
        nmCidade: '',
        cdEstado: '',
        situacaoCadastro: '',
        vsVersao: null,
      },
      uploadedFileCNH: null,
      uploadedFileComprovanteEndereco: null,
      uploadedFileInscricaoInss: null,
      uploadedFileTermoCompromisso: null,
      uploadedFileCertidaoNegativaCriminal: null,
      uploadedFileAtestadoAntecedentesCriminais: null,
    };
  }

  cadastroFinalizado$ = this.cadastroFinalizado.asObservable();

  getFormularioCadastro() {
    return this.formularioCadastro;
  }

  setFormularioCadastro(formularioCadastro) {
    this.formularioCadastro = formularioCadastro;
  }

  complete() {
    this.cadastroFinalizado.next(this.formularioCadastro.cadastroCondutor);
  }

  public create(
    cadastroCondutoresFormData: FormData
  ): Observable<CadastroCondutores> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.post<CadastroCondutores>(
      `${this.requestMapping}/create`,
      cadastroCondutoresFormData,
      { headers }
    );
  }

  public update(
    cadastroCondutoresFormData: FormData
  ): Observable<CadastroCondutores> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.put<CadastroCondutores>(
      this.requestMapping,
      cadastroCondutoresFormData,
      { headers }
    );
  }

  findCondutorByCPF(cpf: string): Observable<CadastroCondutores> {
    console.log('CPF: ' + cpf);
    return this.httpClient.get<CadastroCondutores>(
      `${this.requestMapping}/cpf/${cpf}`
    );
  }

  findCondutoresDaOperadora(
    cnpjOperadora: string
  ): Observable<CadastroCondutores[]> {
    console.log('CNPJ Operadora: ' + cnpjOperadora);
    return this.httpClient.get<CadastroCondutores[]>(
      `${this.env.getHostURL()}/operadora-condutor/buscar-por-cnpj/${cnpjOperadora}`
    );
  }

  public findCondutorById(
    oidCadastroCondutor: number
  ): Observable<CadastroCondutores> {
    console.log(oidCadastroCondutor);
    const retorno = this.httpClient.get<CadastroCondutores>(
      `${this.env.getHostURL()}/condutor/buscar-por-id/${oidCadastroCondutor}`
    );
    console.log(retorno);
    return retorno;
  }

  findModeloTermoCompromissos(): Observable<any> {
    return this.httpClient.get<any>(
      `${this.env.getHostURL()}/condutor/buscar-modelo-termo-compromisso`
    );
  }
}
