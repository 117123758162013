import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs';
import { DatePipe } from '@angular/common';
import { VeiculosService } from 'src/app/page/veiculos/veiculos.service';
import { CadastroVeiculos } from 'src/app/model/cadastro-veiculo.model';

@Component({
  selector: 'app-dialog-veiculo',
  templateUrl: './dialog-veiculo.component.html',
  styleUrls: ['./dialog-veiculo.component.scss']
})
export class DialogVeiculoComponent  implements OnInit{
  
  @Input() displayDialog: boolean;
  @Input() oidCadastroVeiculos: number;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();

  cadastroVeiculo: CadastroVeiculos = {
    oidCadastroVeiculos: null,
    tpVeiculos: '',
    nrPlaca: '',
    dsChassi: '',
    dsModelo: '',
    nrAno: null,
    dsCor: '',
    dsRenavam: '',
    nmProprietario: '',
    dtLaudoVistoria: null,
    oidCadastroCondutor: null,
    situacaoCadastro: '',
    proprietarioCondutor: '',
    nmCondutor: '',
    nrCpfCondutor: '',
    dsUsuAlter: '',
    vsVersao: null,
    uploadedFileCRLV: '',
    uploadedFileLaudoVistoria: '',
  };

  selectedOption: any;

  options: any[] = [
    { name: 'Sim', key: 'S' },
    { name: 'Não', key: 'N' },
  ];

  constructor(
    private veiculosService: VeiculosService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.initializeData();
  }

  private initializeData() {
      this.findVeiculoById();  
  }

  private findVeiculoById() {
    if(this.oidCadastroVeiculos){
      this.spinner.show(); 
  
      this.veiculosService.findVeiculoById(this.oidCadastroVeiculos).pipe(
        catchError((error) => {
          console.error('Erro ao buscar veículo:', error);       
          throw error;
        }),
        finalize(() => {                
          this.spinner.hide(); 
        })
      ).subscribe((data) => {
        if (data && data.oidCadastroVeiculos) {
          console.log(data);
          data.dtLaudoVistoria = this.convertDataFormat(data.dtLaudoVistoria); 
          this.cadastroVeiculo = data;
          this.selectedOption = this.options.find(item => item.key === this.cadastroVeiculo.idProprietarioCondutor);
          console.log('Operação de busca concluída com sucesso.');
        }
      });
    }
    }

    convertDataFormat(data: Date): Date {
      const datePipe = new DatePipe('en-US');
      const dataFormatada = data;
          const dataParaExibir = datePipe.transform(dataFormatada, 'yyyy-MM-dd');
          return new Date(dataParaExibir);
    }

    limpaDialog() {
      this.closeDialog.emit(); 
    }
}

