import { Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { OperadoraService } from './operadora.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-operadora',
    templateUrl: './operadora.component.html',
    styleUrls: ['./operadora.component.scss'],
    providers: [MessageService]
})
export class OperadoraComponent implements OnInit {
    items: MenuItem[];

    subscription: Subscription;

    constructor(public messageService: MessageService, public operadoraService: OperadoraService, private router: Router) { }

    ngOnInit() {
        this.items = [
            {
                label: 'Dados da Operadora',
                routerLink: 'dados'
            },
            {
                label: 'Documentos',
                routerLink: 'documentos'
            }
        ];

        this.subscription = this.operadoraService.cadastroFinalizado$.subscribe((operadora) => {
            this.messageService.add({ severity: 'success', summary: 'Sucesso', detail: 'Operadora cadastrada com sucesso!' });
            this.router.navigate(['operadora/dados']);
        });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
