import { IUsuarioLogado } from './model/usuario';
import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { GlobalVarsService } from './service/global-vars.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private globalVarsService: GlobalVarsService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let usuarioLogado: IUsuarioLogado | null =
      this.globalVarsService.getUserInfo();

    if (!usuarioLogado) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }

  notLogged(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let usuarioLogado: IUsuarioLogado | null =
      this.globalVarsService.getUserInfo();

    if (usuarioLogado) {
      //!tudo altenticar se dados requerente
      if (localStorage.getItem('IdIsImovel') == 'S') {
        this.router.navigate(['/imovel']);
        return false;
      }

      this.router.navigate(['/tela-inicial']);
      return false;
    }

    return true;
  }
}

export const authGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AuthGuard).canActivate(next, state);
};

export const notLoggedGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AuthGuard).notLogged(next, state);
};
