<p-dialog
  header="Buscar Endereço"
  [(visible)]="displayDialog"
  [modal]="true"
  [draggable]="false"
  [breakpoints]="{ '960px': '80vw', '640px': '100vw' }"
  [style]="{ width: '80vw' }"
  (onHide)="limpaDialog()"
>
  <div class="dialog-content">
    <div class="form-div" style="width: 20%">
      <label>UF*</label>
      <p-dropdown
        placeholder="Selecione"
        appendTo="body"
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="estadoList"
        optionLabel="cdEstado"
        optionValue="cdEstado"
        [(ngModel)]="codEstDialog"
        (onChange)="setCidadesDialogByCodEst($event)"
      ></p-dropdown>
    </div>

    <div class="form-div" style="width: 78%">
      <label>Cidade*</label>
      <p-dropdown
        placeholder="Selecione"
        appendTo="body"
        autoWidth="false"
        [style]="{ width: '100%' }"
        [options]="cidadeListDialog"
        optionLabel="nmCidade"
        optionValue="cdCidade"
        [(ngModel)]="codCidDialog"
      ></p-dropdown>
    </div>

    <div class="log-dialog">
      <div class="form-div" style="width: 90%">
        <label>Logradouro*</label>
        <input
          type="text"
          class="input"
          [(ngModel)]="nmLogradouroDialog"
          (input)="formataTextoNome($event)"
        />
      </div>

      <div class="form-div">
        <p-button
          label="Pesquisar"
          class="address-btn"
          (click)="getEnderecoPorNomeCodCid()"
        ></p-button>
      </div>
    </div>
  </div>

  <p-table
    [value]="enderecos"
    responsiveLayout="scroll"
    scrollHeight="flex"
    styleClass="p-datatable-striped"
    [tableStyle]="{ 'min-width': '50rem' }"
    [scrollable]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>CEP</th>
        <th>Logradouro</th>
        <th>Bairro</th>
        <!-- <th>Distrito</th> -->
        <th>Cidade</th>
        <th>Estado</th>
        <th>País</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-endereco>
      <tr>
        <td>{{ endereco?.cep }}</td>
        <td>{{ endereco?.endereco }}</td>
        <td>{{ endereco?.nmBairro }}</td>
        <td>{{ endereco?.nmCidade }}</td>
        <td>{{ endereco?.cdEstado }}</td>
        <td>Brasil</td>
        <td>
          <p-button
            label="Selecionar"
            (click)="selecionarEndereco(endereco)"
          ></p-button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>