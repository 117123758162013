import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CadastroVeiculos } from 'src/app/model/cadastro-veiculo.model';
import { EnvironmentService } from 'src/app/service/environment.service';

@Injectable({
  providedIn: 'root'
})
export class VeiculosService {

  private requestMapping: string = this.env.getHostURL() + '/veiculo';

  constructor(private httpClient: HttpClient, private env: EnvironmentService) {}

  public create(cadastroVeiculosFormData: FormData): Observable<CadastroVeiculos> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.post<CadastroVeiculos>(`${this.requestMapping}/create`, cadastroVeiculosFormData, {headers});
  }

  public update(cadastroVeiculosFormData: FormData): Observable<CadastroVeiculos> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    return this.httpClient.put<CadastroVeiculos>(this.requestMapping, cadastroVeiculosFormData, {headers});
  } 

  findVeiculoPelaPlaca(placa: string): Observable<CadastroVeiculos> {
    console.log('Placa do Veículo: ' + placa);
    return this.httpClient.get<CadastroVeiculos>(`${this.requestMapping}/placa/${placa}`);
  }

  public findVeiculoById(oidCadastroVeiculos: number): Observable<CadastroVeiculos> {
    const retorno = this.httpClient.get<CadastroVeiculos>(`${this.env.getHostURL()}/veiculo/buscar-por-id/${oidCadastroVeiculos}`);
    return retorno;
  }

}
