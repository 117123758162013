import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CondutoresComponent } from './condutores.component';
import { CondutoresDadosComponent } from './condutores-dados/condutores-dados.component';
import { CondutoresDocumentosComponent } from './condutores-documentos/condutores-documentos.component';
import { CondutoresRoutingModule } from './condutores-routing.module';
import { ComponentModule } from 'src/app/component/component.module';
import { CondutoresService } from './condutores.service';

import { DropdownModule } from 'primeng/dropdown';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    CondutoresComponent,
    CondutoresDadosComponent,
    CondutoresDocumentosComponent
  ],
  imports: [
    CommonModule,
    CondutoresRoutingModule,
    ComponentModule,
    DropdownModule,
    StepsModule,
    ToastModule,
    ButtonModule,
    FileUploadModule,
    InputMaskModule,
    ReactiveFormsModule,
    TableModule,
    CalendarModule
  ],
  bootstrap: [CondutoresComponent],
  providers: [ CondutoresService ]
})
export class CondutoresModule { }
