import { Component, OnInit } from '@angular/core';
import { AprovacaoCondutor } from 'src/app/model/aprovacao-condutor.model';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs';
import { AprovacoesService } from '../aprovacoes.service';
import * as FileSaver from 'file-saver';
import { dataURItoBlobTypeZip } from 'src/app/shared/utils/file-utils';

@Component({
  selector: 'app-aprovacoes-condutor',
  templateUrl: './aprovacoes-condutor.component.html',
  styleUrls: ['./aprovacoes-condutor.component.scss'],
})
export class AprovacoesCondutorComponent implements OnInit {
  items: AprovacaoCondutor[];
  displayDialog: boolean = false;
  oidCadastroCondutorSelecionado: number = null;
  displayDialogMotivoReprovacao: boolean = false;
  inputData: any;

  constructor(
    private aprovacoesService: AprovacoesService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.recuperaCondutoresParaAprovacao();
  }

  private recuperaCondutoresParaAprovacao() {
    this.spinner.show();

    this.aprovacoesService
      .findCondutoresParaAprovacao()
      .pipe(
        catchError((error) => {
          console.error('Erro ao buscar condutores para aprovação:', error);
          this.messageService.add({
            severity: 'Error',
            summary: 'Erro ao recuperar condutores para aprovação',
            detail: error,
          });
          throw error;
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((data) => {
        if (data) {
          this.items = data;
          console.log(
            'Operação de busca de condutores para aprovação concluída com sucesso.'
          );
        }
      });
  }

  public selectAll(): void {
    const selectAllCheckbox = document.getElementById(
      'checkboxSelecionarTodos'
    ) as HTMLInputElement;
    this.items.forEach((item) => (item.selected = selectAllCheckbox.checked));
  }

  public aprovarCondutorSelecionada(oidCadastroCondutor: number): void {
    const oidCadastroCondutorList: number[] = [oidCadastroCondutor];
    this.aprovar(oidCadastroCondutorList);
  }

  public aprovarSelecionados(): void {
    const selectedItems = this.items.filter((item) => item.selected);
    if (selectedItems && selectedItems.length > 0) {
      this.spinner.show();

      console.log(selectedItems);
      const oidCadastroCondutorList: number[] = selectedItems.map(
        (item) => item.oidCadastroCondutor || 0
      );

      this.aprovar(oidCadastroCondutorList);
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aprovar Condutor',
        detail: 'Nenhuma condutor foi selecionado para aprovação',
      });
    }
  }

  public aprovar(oidCadastroCondutorList: number[]): void {
    this.aprovacoesService
      .aprovarCondutor(oidCadastroCondutorList)
      .pipe(
        catchError((error) => {
          console.error('Erro ao aprovar condutores:', error);
          this.messageService.add({
            severity: 'Error',
            summary: 'Erro ao aprovar condutores',
            detail: error,
          });
          throw error;
        }),
        finalize(() => {
          this.spinner.hide();
          this.recuperaCondutoresParaAprovacao();
        })
      )
      .subscribe((data) => {
        if (data && data > 0) {
          console.log(
            'Operação de aprovação de condutores concluída com sucesso.'
          );
        } else {
          this.messageService.add({
            severity: 'Aprovar condutores',
            summary:
              'Uma ou mais condutores não puderam ser aprovados. Verifique.',
          });
        }
      });
  }

  public removerCondutorSelecionada(oidCadastroCondutor: number): void {
    const oidCadastroCondutorList: number[] = [oidCadastroCondutor];
    //this.remover(oidCadastroCondutorList);
    this.inputData = { oidList: oidCadastroCondutorList };
    this.displayDialogMotivoReprovacao = true;
  }

  removerSelecionados() {
    const selectedItems = this.items.filter((item) => item.selected);
    if (selectedItems && selectedItems.length > 0) {
      //this.spinner.show();

      console.log(selectedItems);
      const oidCadastroCondutorList: number[] = selectedItems.map(
        (item) => item.oidCadastroCondutor || 0
      );

      //this.remover(oidCadastroCondutorList);
      this.inputData = { oidList: oidCadastroCondutorList };
      this.displayDialogMotivoReprovacao = true;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Remover Condutor',
        detail: 'Nenhuma condutor foi selecionado para remoção',
      });
    }
  }

  public remover(
    oidCadastroCondutorList: number[],
    dsMotivoReprovacao: string
  ): void {
    this.aprovacoesService
      .reprovarCondutor(oidCadastroCondutorList, dsMotivoReprovacao)
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover condutores:', error);
          this.messageService.add({
            severity: 'Error',
            summary: 'Erro ao remover condutores',
            detail: error,
          });
          throw error;
        }),
        finalize(() => {
          this.spinner.hide();
          this.recuperaCondutoresParaAprovacao();
        })
      )
      .subscribe((data) => {
        if (data && data > 0) {
          console.log(
            'Operação de remoção de condutores concluída com sucesso.'
          );
        } else {
          this.messageService.add({
            severity: 'Remover condutores',
            summary:
              'Uma ou mais condutores não puderam ser removidos. Verifique.',
          });
        }
      });
  }

  public abrirModalDeConsulta(oidCadastroCondutor: number): void {
    this.displayDialog = true;
    this.oidCadastroCondutorSelecionado = oidCadastroCondutor;
  }

  public closeDialog(): void {
    this.displayDialog = false;
  }

  onDialogMotivoClose(event: any) {
    this.displayDialogMotivoReprovacao = false;

    if (event) this.remover(event.oidList, event.dsMotivoReprovacao);
  }

  baixarArquivos(oidCondutor: number) {
    this.aprovacoesService.baixarAnexosCondutorPorId(oidCondutor).subscribe({
      next: (response) => {
        if (response) {
          const blob = dataURItoBlobTypeZip(response.arqBase64Bytes);
          FileSaver.saveAs(blob, `${response.nomeArquivo}`);
        }
      },
      error: (err) =>
        console.error('Erro ao baixar arquivos do condutor:', err),
    });
  }
}
