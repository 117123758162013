<p-dialog
  header=""
  [(visible)]="displayDialog"
  [modal]="true"
  [draggable]="false"
  [breakpoints]="{ '960px': '80vw', '640px': '100vw' }"
  [style]="{ width: '80vw' }"
  (onHide)="limpaDialog()"
>
  <div style="width: 100%">
    <div class="content">
      <form ngNoForm>
        <h3>Cadastro de Operadora</h3>

        <div class="form-row" *ngIf="oidCadastroOperadoras">
          <div class="form-div" style="width: 50%">
            <label for="nmCondutor">Situação</label>
            <input
              type="text"
              pInputText
              [value]="
                cadastroOperadora?.tpSituacao == 'P'
                  ? 'Pendente'
                  : cadastroOperadora?.tpSituacao == 'A'
                  ? 'Aprovado'
                  : cadastroOperadora?.tpSituacao == 'R'
                  ? 'Reprovado'
                  : ''
              "
              class="input"
              required
              maxlength="20"
              [readonly]="true"
            />
          </div>
          <div class="form-div" style="width: 50%">
            <label for="nmCondutor">Observação</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.dsObservacao"
              class="input"
              required
              maxlength="50"
              [readonly]="true"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-div" style="width: 100%">
            <label for="nomefantasia">Nome Fantasia</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.nmFantasia"
              class="input"
              required
              maxlength="20"
              [readonly]="true"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-div" style="width: 100%">
            <label for="razaosocial">Razão Social</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.nmRazaoSocial"
              class="input"
              required
              maxlength="60"
              [readonly]="true"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-div" style="width: 29%">
            <label for="cnpj">CNPJ</label>
            <p-inputMask
              mask="99.999.999/9999-99"
              [unmask]="true"
              [(ngModel)]="cadastroOperadora.nrCnpj"
              [readonly]="true"
            ></p-inputMask>
          </div>
          <div class="form-div" style="width: 29%">
            <label>E-mail</label>
            <input
              type="email"
              pInputText
              [(ngModel)]="cadastroOperadora.dsEmail"
              class="input"
              required
              maxlength="100"
              [readonly]="true"
            />
          </div>
          <div class="form-div" style="width: 29%">
            <label>Telefone</label>
            <p-inputMask
              mask="(99)99999-9999"
              [unmask]="true"
              [(ngModel)]="cadastroOperadora.nrTelefoneContato"
              [readonly]="true"
            ></p-inputMask>
          </div>
        </div>

        <div class="form-row">
          <div class="form-div" style="width: 30%">
            <label>Pessoa de Contato</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.nmContato"
              class="input"
              required
              maxlength="20"
              [readonly]="true"
            />
          </div>
        </div>

        <h3>Endereço</h3>

        <div class="form-row">
          <div class="form-div" style="width: 100%">
            <label>Logradouro</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.dsLogradouro"
              class="input"
              required
              maxlength="280"
              [readonly]="true"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-div" style="width: 30%">
            <label>Bairro</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.nmBairro"
              class="input"
              required
              maxlength="100"
              [readonly]="true"
            />
          </div>
          <div class="form-div" style="width: 30%">
            <label>Número</label>
            <input
              type="number"
              pInputText
              [(ngModel)]="cadastroOperadora.nrNroLogradouro"
              class="input"
              required
              min="0"
              max="9999999999"
              maxlength="10"
              [readonly]="true"
            />
          </div>
          <div class="form-div" style="width: 30%">
            <label>CEP</label>
            <p-inputMask
              mask="99999-999"
              [(ngModel)]="cadastroOperadora.nrCep"
              [unmask]="true"
              [readonly]="true"
            ></p-inputMask>
          </div>
        </div>

        <div class="form-row">
          <div class="form-div" style="width: 100%">
            <label>Complemento</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.dsComplemento"
              class="input"
              maxlength="50"
              [readonly]="true"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-div" style="width: 30%">
            <label>Cidade</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.nmCidade"
              class="input"
              required
              maxlength="50"
              [readonly]="true"
            />
          </div>
          <div class="form-div" style="width: 30%">
            <label>Estado</label>
            <input
              type="text"
              pInputText
              [(ngModel)]="cadastroOperadora.cdEstado"
              class="input"
              required
              maxlength="50"
              [readonly]="true"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
