import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { EnderecoService } from 'src/app/service/endereco.service';
import { OperadoraService } from '../operadora.service';
import { CadastroOperadora } from 'src/app/model/cadastro-operadora.model';
import { Localizacao } from 'src/app/model/localizacao.model';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { catchError, finalize } from 'rxjs';

@Component({
  selector: 'app-operadora-dados',
  templateUrl: './operadora-dados.component.html',
  styleUrls: ['./operadora-dados.component.scss']
})
export class OperadoraDadosComponent implements OnInit {
  public formGroup: FormGroup;
  estadoList: any[] = [];
  submitted: boolean = false;
  cadastroOperadora: CadastroOperadora;
  localizacoes: Localizacao[];
  displayDialog: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private enderecoService: EnderecoService,
    private operadoraService: OperadoraService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private globalVarsService: GlobalVarsService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.initializeFormGroup();
    this.initializeData();
  }

  private initializeData() {
    const cnpj = this.getUserCNPJ();
    const parametro = this.route.snapshot.params['acao'];
    this.getEstados();
    this.cadastroOperadora = this.operadoraService.getFormularioCadastro()?.cadastroOperadora;

    if (parametro !== 'voltar') {
      this.findOperadoraByCnpj(cnpj);
    }else{
      this.patchFormValues();
    }

    
  }

  private findOperadoraByCnpj(cnpj: string) {
    this.spinner.show(); 
  
    this.operadoraService.findOperadoraByCnpj(cnpj).pipe(
      catchError((error) => {
        console.error('Erro ao buscar operadora:', error);
        this.messageService.add({ severity: 'Error', summary: 'Erro ao recuperar operadora', detail: error });
        throw error;
      }),
      finalize(() => {                
        this.spinner.hide(); 
      })
    ).subscribe((data) => {
      if (data && data.oidCadastroOperadoras) {
        this.operadoraService.getFormularioCadastro().cadastroOperadora = data;
        this.cadastroOperadora = this.operadoraService.getFormularioCadastro()?.cadastroOperadora;
        this.patchFormValues();
        console.log('Operação de busca concluída com sucesso.');
      }
    });
  }

  private patchFormValues() {
    this.formGroup.patchValue({
      nomefantasia: this.cadastroOperadora.nmFantasia,
      razaosocial: this.cadastroOperadora.nmRazaoSocial,
      cnpj: this.cadastroOperadora.nrCnpj ? this.cadastroOperadora.nrCnpj : this.getUserCNPJ(),
      email: this.cadastroOperadora.dsEmail,
      telefone: this.cadastroOperadora.nrTelefoneContato,
      pessoaContato: this.cadastroOperadora.nmContato,
      logradouro: this.cadastroOperadora.dsLogradouro,
      bairro: this.cadastroOperadora.nmBairro,
      numero: this.cadastroOperadora.nrNroLogradouro,
      cep: this.cadastroOperadora.nrCep,
      complemento: this.cadastroOperadora.dsComplemento,
      cidade: this.cadastroOperadora.nmCidade,
      estado: this.cadastroOperadora.cdEstado,
      tpSituacao: this.cadastroOperadora?.tpSituacao,
      dsObservacao: this.cadastroOperadora?.dsObservacao,
    });
  }

  private initializeFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      nomefantasia: ['', [Validators.required, Validators.maxLength(20)]],
      razaosocial: ['', [Validators.required, Validators.maxLength(60)]],
      cnpj: [this.getUserCNPJ(), [Validators.required]],
      email: ['', [Validators.required, Validators.maxLength(100)]],
      telefone: ['', [Validators.required]],
      pessoaContato: ['', [Validators.required, Validators.maxLength(20)]],
      logradouro: ['', [Validators.required, Validators.maxLength(100)]],
      bairro: ['', [Validators.required, Validators.maxLength(100)]],
      numero: ['', [Validators.required, Validators.maxLength(10), Validators.min(0), Validators.max(999999999)]],
      cep: ['', [Validators.required, Validators.maxLength(100)]],
      complemento: ['', [Validators.maxLength(50)]],
      cidade: ['', [Validators.maxLength(50)]],
      estado: ['', [Validators.required]],
      tpSituacao: [''],
      dsObservacao: [''],
    });
  }

  getUserCNPJ(): string {
    return this.globalVarsService.getUserInfo()?.cnpj;
  }

  getEstados() {
  this.spinner.show(); 
  
  this.enderecoService.findEstados().pipe(
    catchError((error) => {
      console.error('Erro ao buscar estados:', error);
      this.messageService.add({ severity: 'Error', summary: 'Erro ao recuperar estados', detail: error });
      throw error;
    }),
    finalize(() => {                
      this.spinner.hide(); 
    })
  ).subscribe((data) => {
    if (data) {
      this.estadoList = data;
      console.log('Operação de busca concluída com sucesso.');
    }else{
      this.messageService.add({ severity: 'info', summary: 'Estados', detail: 'Não foram encontrados estados na base de dados' });
      console.log('Não foram encontrados estados na base de dados.');
    }
  });
}
  

  copiarDadosParaCadastroOperadora() {
    const formValue = this.formGroup.value;

    this.cadastroOperadora.nmFantasia = formValue.nomefantasia;
    this.cadastroOperadora.nmRazaoSocial = formValue.razaosocial;
    this.cadastroOperadora.nrCnpj = formValue.cnpj;
    this.cadastroOperadora.dsEmail = formValue.email;
    this.cadastroOperadora.nrTelefoneContato = formValue.telefone;
    this.cadastroOperadora.nmContato = formValue.pessoaContato;
    this.cadastroOperadora.dsLogradouro = formValue.logradouro;
    this.cadastroOperadora.nmBairro = formValue.bairro;
    this.cadastroOperadora.nrNroLogradouro = formValue.numero;
    this.cadastroOperadora.nrCep = formValue.cep;
    this.cadastroOperadora.dsComplemento = formValue.complemento;
    this.cadastroOperadora.nmCidade = formValue.cidade;
    this.cadastroOperadora.cdEstado = formValue.estado;
  }

  nextPage() {
    if (this.formGroup.valid) {
      this.copiarDadosParaCadastroOperadora();
      this.configuraSituacaoCadastro();
      this.router.navigate(['operadora/documentos']);
      return;
    }

    this.submitted = true;
  }

  configuraSituacaoCadastro(): void {
    if (this.cadastroOperadora.oidCadastroOperadoras == null) {
      this.cadastroOperadora.situacaoCadastro = 'P';
    }
  }

  buscarEndereco() {
    const cep: string = this.formGroup.get('cep').value;
    if (cep != null && cep.length == 8) {
      this.spinner.show(); 
  
      this.enderecoService.findPorCep(cep).subscribe(
        (data) => {
          this.localizacoes = data;
          if (this.localizacoes.length > 1) this.displayDialog = true;
          if (this.localizacoes.length == 1) this.selecionarEndereco(this.localizacoes[0]);
          if (this.localizacoes.length == 0) {
            this.displayDialog = true;
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail: `CEP não localizado!`,
            });
          }
        },
        (error) => {
          this.displayDialog = true;
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail: `CEP não localizado!`,
          });
          this.spinner.hide(); 
        },
        () => {
          this.spinner.hide(); 
        }
      );
    } else {
      this.messageService.add({ severity: 'info', summary: 'Buscar CEP', detail: 'O campo CEP deve estar preenchido para fazer a busca' });
    }
  }

  selecionarEndereco(localizacao: any) {
    const endereco: Localizacao = new Localizacao(
      localizacao.cdEstado,
      localizacao.cdCidade,
      localizacao.cep,
      localizacao.cliente,
      localizacao.endereco,
      localizacao.nmBairro,
      localizacao.nmCidade,
      localizacao.nmLogradouro,
      localizacao.nrLogradouroFinal,
      localizacao.nrLogradouroInicial,
      localizacao.tpLogradouro,
      localizacao.tpNumeracaoCep
    );

    this.formGroup.get('logradouro').setValue(endereco.endereco);
    this.formGroup.get('bairro').setValue(endereco.nmBairro);
    this.formGroup.get('numero').setValue(null);
    this.formGroup.get('complemento').setValue('');
    this.formGroup.get('cidade').setValue(endereco.nmCidade);
    this.formGroup.get('cep').setValue(endereco.cep);

    const estadoSelecionado = this.estadoList.find(item => item.cdEstado === endereco.cdEstado);

    if (estadoSelecionado) {
      this.formGroup.get('estado').setValue(estadoSelecionado.cdEstado);
    }

    this.displayDialog = false;
  }
}