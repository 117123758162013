import { Component, OnInit } from '@angular/core';
import { AprovacaoOperadora } from 'src/app/model/aprovacao-operadora.model';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize } from 'rxjs';
import { AprovacoesService } from '../aprovacoes.service';
import * as FileSaver from 'file-saver';
import { dataURItoBlobTypeZip } from 'src/app/shared/utils/file-utils';

@Component({
  selector: 'app-aprovacoes-operadora',
  templateUrl: './aprovacoes-operadora.component.html',
  styleUrls: ['./aprovacoes-operadora.component.scss'],
})
export class AprovacoesOperadoraComponent implements OnInit {
  items: AprovacaoOperadora[];
  displayDialog: boolean = false;
  oidCadastroOperadorasSelecionado: number = null;
  displayDialogMotivoReprovacao: boolean = false;
  inputData: any;

  constructor(
    private aprovacoesService: AprovacoesService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.recuperaOperadorasParaAprovacao();
  }

  private recuperaOperadorasParaAprovacao() {
    this.spinner.show();

    this.aprovacoesService
      .findOperadorasParaAprovacao()
      .pipe(
        catchError((error) => {
          console.error('Erro ao buscar operadoras para aprovação:', error);
          this.messageService.add({
            severity: 'Error',
            summary: 'Erro ao recuperar operadoras para aprovação',
            detail: error,
          });
          throw error;
        }),
        finalize(() => {
          this.spinner.hide();
        })
      )
      .subscribe((data) => {
        if (data) {
          this.items = data;
          console.log(
            'Operação de busca de operadoras para aprovação concluída com sucesso.'
          );
        }
      });
  }

  public selectAll(): void {
    const selectAllCheckbox = document.getElementById(
      'checkboxSelecionarTodos'
    ) as HTMLInputElement;
    this.items.forEach((item) => (item.selected = selectAllCheckbox.checked));
  }

  public aprovarOperadoraSelecionada(oidCadastroOperadoras: number): void {
    const oidCadastroOperadorasList: number[] = [oidCadastroOperadoras];
    this.aprovar(oidCadastroOperadorasList);
  }

  public aprovarSelecionados(): void {
    const selectedItems = this.items.filter((item) => item.selected);
    if (selectedItems && selectedItems.length > 0) {
      this.spinner.show();

      console.log(selectedItems);
      const oidCadastroOperadorasList: number[] = selectedItems.map(
        (item) => item.oidCadastroOperadoras || 0
      );

      this.aprovar(oidCadastroOperadorasList);
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aprovar Operadora',
        detail: 'Nenhuma operadora foi selecionada para aprovação',
      });
    }
  }

  public aprovar(oidCadastroOperadorasList: number[]): void {
    this.aprovacoesService
      .aprovarOperadora(oidCadastroOperadorasList)
      .pipe(
        catchError((error) => {
          console.error('Erro ao aprovar operadoras:', error);
          this.messageService.add({
            severity: 'Error',
            summary: 'Erro ao aprovar operadoras',
            detail: error,
          });
          throw error;
        }),
        finalize(() => {
          this.spinner.hide();
          this.recuperaOperadorasParaAprovacao();
        })
      )
      .subscribe((data) => {
        if (data && data > 0) {
          console.log(
            'Operação de aprovação de operadoras concluída com sucesso.'
          );
        } else {
          this.messageService.add({
            severity: 'Aprovar operadoras',
            summary:
              'Uma ou mais operadoras não puderam ser aprovadas. Verifique.',
          });
        }
      });
  }

  public removerOperadoraSelecionada(oidCadastroOperadoras: number): void {
    const oidCadastroOperadorasList: number[] = [oidCadastroOperadoras];
    //this.remover(oidCadastroOperadorasList);
    this.inputData = { oidList: oidCadastroOperadorasList };
    this.displayDialogMotivoReprovacao = true;
  }

  removerSelecionados() {
    const selectedItems = this.items.filter((item) => item.selected);
    if (selectedItems && selectedItems.length > 0) {
      //this.spinner.show();

      console.log(selectedItems);
      const oidCadastroOperadorasList: number[] = selectedItems.map(
        (item) => item.oidCadastroOperadoras || 0
      );

      //this.remover(oidCadastroOperadorasList);
      this.inputData = { oidList: oidCadastroOperadorasList };
      this.displayDialogMotivoReprovacao = true;
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Remover Operadora',
        detail: 'Nenhuma operadora foi selecionada para remoção',
      });
    }
  }

  public remover(
    oidCadastroOperadorasList: number[],
    dsMotivoReprovacao: string
  ): void {
    this.aprovacoesService
      .reprovarOperadora(oidCadastroOperadorasList, dsMotivoReprovacao)
      .pipe(
        catchError((error) => {
          console.error('Erro ao remover operadoras:', error);
          this.messageService.add({
            severity: 'Error',
            summary: 'Erro ao remover operadoras',
            detail: error,
          });
          throw error;
        }),
        finalize(() => {
          this.spinner.hide();
          this.recuperaOperadorasParaAprovacao();
        })
      )
      .subscribe((data) => {
        if (data && data > 0) {
          console.log(
            'Operação de remoção de operadoras concluída com sucesso.'
          );
        } else {
          this.messageService.add({
            severity: 'Remover operadoras',
            summary:
              'Uma ou mais operadoras não puderam ser removidas. Verifique.',
          });
        }
      });
  }

  public abrirModalDeConsulta(oidCadastroOperadoras: number): void {
    this.displayDialog = true;
    this.oidCadastroOperadorasSelecionado = oidCadastroOperadoras;
  }

  public closeDialog(): void {
    this.displayDialog = false;
  }

  onDialogMotivoClose(event: any) {
    this.displayDialogMotivoReprovacao = false;

    if (event) this.remover(event.oidList, event.dsMotivoReprovacao);
  }

  baixarArquivos(oidOperadora: number) {
    this.aprovacoesService.baixarAnexosOperadoraPorId(oidOperadora).subscribe({
      next: (response) => {
        if (response) {
          const blob = dataURItoBlobTypeZip(response.arqBase64Bytes);
          FileSaver.saveAs(blob, `${response.nomeArquivo}`);
        }
      },
      error: (err) =>
        console.error('Erro ao baixar arquivos da operadora:', err),
    });
  }
}
