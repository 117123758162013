<p-toast></p-toast>

<div class="card">
    <p-toast></p-toast>
    <p-steps [model]="items" [readonly]="false"></p-steps>
</div>
<router-outlet></router-outlet>


<!-- <div class="content" *ngIf="!isOperadoraAprovada">
    <h3>Veículos</h3>
    <div class="linha-msg" style="width: 100%">
        <p class="p-error">Não é possível cadastrar veículos. A operadora ainda não foi aprovada.</p>
    </div>
</div> -->