<div style="width: 100%">
    <div class="content">
        <form>
            <h3>Documentos da Operadora</h3>
            <h4>Adicione abaixo o(s) documento(s) solicitado(s)*</h4>

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="contratoSocial">Adicionar Contrato Social*</label>
                    <p-fileUpload 
                        mode="basic" 
                        chooseLabel="Clique aqui para adicionar (pdf)" 
                        name="contratoSocial[]" 
                        accept="application/pdf"
                        [maxFileSize]="5119998"
                        [auto]="true"
                        [customUpload]="true"
                        (uploadHandler)="onUpload($event, 'uploadedFileContratoSocial')"
                        chooseIcon="pi-upload"
                        class="uploadButton">
                    </p-fileUpload>
                    <small *ngIf="uploadedFiles.get('uploadedFileContratoSocial') !== null" class="file-uploaded-info">{{ uploadedFiles.get('uploadedFileContratoSocial')?.name }}</small>
                </div>
            </div>      

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="certidaoNegativa">Adicionar Certidão Negativa de Débitos no Município*</label>
                    <p-fileUpload 
                        mode="basic" 
                        chooseLabel="Clique aqui para adicionar (pdf)" 
                        name="certidaoNegativa[]"  
                        accept="application/pdf"
                        [maxFileSize]="5119998"
                        [auto]="true"
                        [customUpload]="true"
                        (uploadHandler)="onUpload($event, 'uploadedFileCertidaoNegativa')"
                        chooseIcon="pi-upload"
                        class="uploadButton">
                    </p-fileUpload>
                    <small *ngIf="uploadedFiles.get('uploadedFileCertidaoNegativa') !== null" class="file-uploaded-info">{{ uploadedFiles.get('uploadedFileCertidaoNegativa')?.name }}</small>
                </div>
            </div>
            
            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="cartaoCnpj">Adicionar Cartão CNPJ*</label>
                    <p-fileUpload 
                        mode="basic" 
                        chooseLabel="Clique aqui para adicionar (pdf)" 
                        name="cartaoCnpj[]" 
                        accept="application/pdf"
                        [maxFileSize]="5119998"
                        [auto]="true"
                        [customUpload]="true"
                        (uploadHandler)="onUpload($event, 'uploadedFileCartaoCnpj')"
                        chooseIcon="pi-upload"
                        class="uploadButton">
                    </p-fileUpload>
                    <small *ngIf="uploadedFiles.get('uploadedFileCartaoCnpj') !== null" class="file-uploaded-info">{{ uploadedFiles.get('uploadedFileCartaoCnpj')?.name }}</small>
                </div>
            </div> 

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="comprovanteInss">Adicionar Comprovante de Regularidade INSS*</label>
                    <p-fileUpload 
                        mode="basic" 
                        chooseLabel="Clique aqui para adicionar (pdf)" 
                        name="comprovanteInss[]" 
                        accept="application/pdf"
                        [maxFileSize]="5119998"
                        [auto]="true"
                        [customUpload]="true"
                        (uploadHandler)="onUpload($event, 'uploadedFileComprovanteRegularidadeInss')"
                        chooseIcon="pi-upload"
                        class="uploadButton">
                    </p-fileUpload>
                    <small *ngIf="uploadedFiles.get('uploadedFileComprovanteRegularidadeInss') !== null" class="file-uploaded-info">{{ uploadedFiles.get('uploadedFileComprovanteRegularidadeInss')?.name }}</small>
                </div>
            </div> 

            <div class="form-row">
                <div class="form-div" style="width: 100%">
                    <label for="comprovanteFgts">Adicionar Comprovante de Regularidade FGTS*</label>
                    <p-fileUpload 
                        mode="basic" 
                        chooseLabel="Clique aqui para adicionar (pdf)" 
                        name="comprovanteFgts[]" 
                        accept="application/pdf"
                        [maxFileSize]="5119998"
                        [auto]="true"
                        [customUpload]="true"
                        (uploadHandler)="onUpload($event, 'uploadedFileComprovanteRegularidadeFgts')"
                        chooseIcon="pi-upload"
                        class="uploadButton">
                    </p-fileUpload>
                    <small *ngIf="uploadedFiles.get('uploadedFileComprovanteRegularidadeFgts') !== null" class="file-uploaded-info">{{ uploadedFiles.get('uploadedFileComprovanteRegularidadeFgts')?.name }}</small>
                </div>
            </div>

            <div>
                <p>
                    <p-checkbox [(ngModel)]="check" [binary]="true" inputId="binary" name="binary"></p-checkbox>
                    <label for="binary">
                        Declaro, sob as penas da lei, que no momento do cadastro dos veículos e dos condutore, serão juntados todos documentos necessários a atender às exigências da Lei Municipal 13.982/2023:
                    </label>
                </p>
            </div>

            <div class="form-footer">
                <p-button label="Voltar" styleClass="p-button-success custom-button" (onClick)="prevPage()">
                </p-button>

                <p-button label="Concluir" [disabled]="!check" styleClass="p-button-info custom-button" (onClick)="complete()" icon="pi pi-angle-right" iconPos="right">
                </p-button>
            </div>
        </form>
    </div>
</div>