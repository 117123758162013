<p-dialog header="" [(visible)]="displayDialog" [modal]="true" [draggable]="false"
    [breakpoints]="{ '960px': '80vw', '640px': '100vw' }" [style]="{ width: '80vw' }" (onHide)="limpaDialog()">

    <div style="width: 100%">
        <div class="content">
            <form ngNoForm>
                <h3>Cadastro de Veículos</h3>

                <div class="form-row" *ngIf="oidCadastroVeiculos">
                    <div class="form-div" style="width: 50%">
                        <label for="nmCondutor">Situação</label>
                        <input type="text" pInputText [value]="
                        cadastroVeiculo?.tpSituacao == 'P'
                          ? 'Pendente'
                          : cadastroVeiculo?.tpSituacao == 'A'
                          ? 'Aprovado'
                          : cadastroVeiculo?.tpSituacao == 'R'
                          ? 'Reprovado'
                          : ''
                      " class="input" required maxlength="20" [readonly]="true" />
                    </div>
                    <div class="form-div" style="width: 50%">
                        <label for="nmCondutor">Observação</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.dsObservacao" class="input" required maxlength="50" [readonly]="true" />
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-div" style="width: 30%">
                        <label>Nome Condutor*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.nmCondutor" class="input" maxlength="50" [readonly]="true" />                        
                    </div>                    
                    <div class="form-div" style="width: 30%">
                        <label>CPF Condutor*</label>
                        <p-inputMask mask="999.999.999-99" [unmask]="true" [(ngModel)]="cadastroVeiculo.nrCpfCondutor" [readonly]="true" />                      
                    </div>                           
                </div>

                <div class="form-row">                   
                    <div class="form-div" style="width: 30%">
                        <label for="nrPlaca">Placa do Veículo*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.nrPlaca" class="input" maxlength="7" [readonly]="true" />
                    </div>

                    <div class="form-div" style="width: 30%">
                        <label for="tpVeiculos">Tipo do Veículo*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.tpVeiculos" class="input" maxlength="20" [readonly]="true" />
                    </div>

                </div>

                <div class="form-row"> 
                    <div class="form-div" style="width: 30%">
                        <label for="dsModelo">Modelo do Veículo*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.dsModelo" class="input" maxlength="15" [readonly]="true" />
                    </div>
                </div>

                <div class="form-row">
                    
                    <div class="form-div" style="width: 30%">
                        <label for="dsCor">Cor do Veículo*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.dsCor" class="input" maxlength="20" [readonly]="true" />
                    </div>
                    <div class="form-div" style="width: 30%">
                        <label for="nrAno">Ano de Fabricação*</label>
                        <input type="number" pInputText [(ngModel)]="cadastroVeiculo.nrAno" class="input" min="1900" max="9999" maxlength="4" [readonly]="true" />
                    </div>
                </div>

                <div class="form-row">
                    
                    <div class="form-div" style="width: 30%">
                        <label for="dsChassi">Chassi*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.dsChassi" class="input" maxlength="17" [readonly]="true" />
                    </div>
                    <div class="form-div" style="width: 30%">
                        <label for="dsRenavam">Renavam*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.dsRenavam" class="input" maxlength="9" [readonly]="true" />
                    </div>
                </div>

                <div class="form-row">
                    
                    <label for="proprietarioCondutor">O proprietário é condutor do veículo?</label>
                    <div *ngFor="let option of options" class="field-checkbox">
                        <p-radioButton [inputId]="option.key" [value]="option"
                            [(ngModel)]="selectedOption" [disabled]="true"></p-radioButton>
                        <label [for]="option.key" class="ml-2">{{ option.name }}</label>
                    </div>
                </div>

                <div class="form-row">                    
                    <div class="form-div" style="width: 30%">
                        <label for="nmProprietario">Proprietário do Veículo*</label>
                        <input type="text" pInputText [(ngModel)]="cadastroVeiculo.nmProprietario" class="input" maxlength="50" [readonly]="true" />
                    </div>
                </div>
                
            </form>
        </div>
    </div>

</p-dialog>