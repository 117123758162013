<div class="container-header">
    <div class="container-left">
    </div>
    <div class="container-right">
        <div class="icons">
            <a href="http://www.facebook.com/PrefeituraUdi/" target="_blank">
                <div class="oval">
                    <img src="../../../assets/facebook.png">
                </div>
            </a>
            <a href="http://twitter.com/PrefeituraUdia" target="_blank">
                <div class="oval">
                    <img src="../../../assets/twitter.png">
                </div>
            </a>
            <a href="http://www.instagram.com/prefeituradeuberlandia/" target="_blank">
                <div class="oval">
                    <img src="../../../assets/instagram.png">
                </div>
            </a>
            <a href="http://api.whatsapp.com/send?1=pt_BR&phone=553432392800" target="_blank">
                <div class="oval">
                    <img src="../../../assets/whatsapp.png">
                </div>
            </a>
        </div>
    </div>

</div>